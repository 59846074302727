import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { Button } from 'src/common/components/Button';
import discoverImage from 'src/assets/images/discover.jpg';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  background: url('${discoverImage}') no-repeat bottom center;
  background-size: auto 27.3125rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 1.75rem 1.5625rem 24.6875rem;
  text-align: center;
  width: 85.375rem;

  ${media.w.min.px992(css`
    background-position: bottom right -6.25rem;
    background-size: auto;
    padding: 8rem 1.125rem;
    text-align: left;
  `)}

  ${media.w.min.px1200(css`
    background-position: bottom right;
    padding: 11.875rem 1.125rem 9.1875rem;
  `)}

  ${media.w.min.px1366(css`
    padding-left: 5.125rem;
    padding-right: 5.125rem;
  `)}
`;

const StyledButton = styled(Button)`
  width: 10.75rem;
`;

const Text = styled.h2`
  color: ${colors.grey1};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin: 0 auto 0.9375rem;
  max-width: 23.4375rem;

  ${media.w.min.px992(css`
    font-size: 1.0625rem;
    line-height: 1.5rem;
    margin: 0 0 1.875rem;
    max-width: 25.75rem;
  `)}
`;

const Title = styled.div`
  color: ${colors.itpGold};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 1.5rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;

  ${media.w.max.px992(css`
    margin-bottom: 1.25rem;
  `)}
`;

const Wrapper = styled.div`
  background: ${colors.white};
  padding-top: 3.875rem;

  ${media.w.max.px992(css`
    padding-top: 6.875rem;
  `)}
`;

export const HomepageDiscover: FC = () => (
  <Wrapper>
    <Container id="discover">
      <Text>
        Specjalizujemy się w dostarczaniu najwyższej jakości, opatentowanych
        technologii laserowych i medycznych na rynek polski i zagraniczny.
        Wyposażamy gabinety medycyny estetycznej, gabinety kosmetologiczne,
        placówki spa i wellness.
      </Text>

      <Title>Poznaj nasze technologie!</Title>

      <StyledButton
        as="a"
        href="https://itpestetyka.pl/"
        kind="black"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        Więcej
      </StyledButton>
    </Container>
  </Wrapper>
);
