import React, { CSSProperties, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { useDerivedState } from 'src/lib/useDerivedState';
import { rem } from 'polished';

const InvisibleCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  cursor: inherit;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const LabelText = styled.span<{ footer?: boolean }>`
  color: ${({ footer }) => (footer ? colors.grey4 : colors.white)};
  font-weight: ${({ footer }) => (footer ? '400' : '300')};
  font-size: 0.75rem;
  line-height: 1.125rem;
  user-select: none;
`;

const Label = styled.label<{ disabled?: boolean }>`
  align-items: flex-start;
  display: inline-flex;
  text-align: left;
  vertical-align: middle;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
`;

export const InputWrapper = styled.div<{
  disabled?: boolean;
  $label?: boolean;
}>`
  display: flex;
  margin-top: 0.25rem;
  padding: ${({ $label }) => ($label ? '0 0.625rem 0 0' : '0')};
  position: relative;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}
`;

const CheckboxInput = styled(InvisibleCheckboxInput)`
  ${(props) =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        & + svg #border,
        & + svg #checkmark,
        & + svg #not-checked {
          stroke: ${colors.white08};
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      & + svg #border,
      & + svg #checkmark,
      & + svg #not-checked {
        stroke: ${colors.white05};
      }
    `}
`;

export interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  footer?: boolean;
  initialValue?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (v: boolean) => void;
  size?: number;
  style?: CSSProperties;
  value?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  disabled,
  footer,
  initialValue,
  label,
  name,
  onChange,
  size = 19,
  style,
  value,
}) => {
  const [active, setActive] = useDerivedState(initialValue ?? false, {
    value,
    onChange,
  });

  return (
    <Label className={className} style={style} disabled={disabled}>
      <InputWrapper disabled={disabled} $label={!!label}>
        <CheckboxInput
          name={name}
          checked={active}
          onChange={() => setActive((currentValue: boolean) => !currentValue)}
          disabled={disabled}
        />
        <svg
          width={size}
          height={size}
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!active && (
            <rect
              width="18"
              height="18"
              x="1"
              y="1"
              stroke={colors.white05}
              id="not-checked"
            />
          )}
          {active && (
            <>
              <path
                stroke={colors.white08}
                strokeWidth="2"
                id="checkmark"
                d="M7 9l3 3L20 2"
              />
              <path
                stroke={colors.white08}
                strokeWidth="2"
                id="border"
                d="M19 10v7c0 .5304-.2107 1.0391-.5858 1.4142S17.5304 19 17 19H3c-.53043 0-1.03914-.2107-1.41421-.5858C1.21071 18.0391 1 17.5304 1 17V3c0-.53043.21071-1.03914.58579-1.41421C1.96086 1.21071 2.46957 1 3 1h11"
              />
            </>
          )}
        </svg>
      </InputWrapper>

      <LabelText footer={footer} style={{ paddingRight: rem(0) }}>
        {label}
      </LabelText>
    </Label>
  );
};
