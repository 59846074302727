import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { HomepageFeaturedBanner } from 'src/public/components/homepage/HomepageFeatured/HomepageFeaturedBanner';
import { HomepageFeaturedVideos } from 'src/public/components/homepage/HomepageFeatured/HomepageFeaturedVideos';
import { HomepageFeaturedBackground } from 'src/public/components/homepage/HomepageFeatured/HomepageFeaturedBackground';
import { HomepageFeaturedMore } from 'src/public/components/homepage/HomepageFeatured/HomepageFeaturedMore';
import { ScrollOffset } from 'src/common/components/ScrollOffset';
import { MaxWidthContainer } from 'src/common/components/Containers';
import { media } from 'src/config/breakpoints';

const Wrapper = styled(MaxWidthContainer)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${media.w.max.px1200(css`
    max-width: 100%;
  `)}
`;

const HomepageFeaturedVideosWrapper = styled.div`
  ${media.w.max.px768(css`
    display: none;
  `)}
`;

export const featuredWrapperId = 'featured-wrapper';

export const HomepageFeatured: FC = () => {
  return (
    <div id={featuredWrapperId}>
      <HomepageFeaturedBackground />
      <Wrapper>
        <ScrollOffset id="featured" />

        <HomepageFeaturedBanner />
        <HomepageFeaturedVideosWrapper>
          <HomepageFeaturedVideos />
        </HomepageFeaturedVideosWrapper>
        <HomepageFeaturedMore />
      </Wrapper>
    </div>
  );
};
