import styled from 'styled-components';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const ScrollContainer = styled(OverlayScrollbarsComponent)`
  width: 100%;

  & .os-content {
    display: flex;
  }

  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0;
    height: 0.1875rem;
    width: 0.1875rem;
  }

  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  & > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
    background: rgba(255, 255, 255, 1);
  }
`;
