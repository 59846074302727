import { useEffect } from 'react';

export const useKeyPress = (targetKey: number, callback: () => void) => {
  useEffect(() => {
    const handlePress = (event: KeyboardEvent) => {
      if (event.keyCode === targetKey) {
        callback();
      }
    };

    window.addEventListener('keydown', handlePress);
    return () => {
      window.removeEventListener('keydown', handlePress);
    };
  }, [targetKey, callback]);
};
