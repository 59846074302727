import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { publicationsActions } from 'src/state/publications/publicationsActions';
import { publicationsFiltersSelector } from 'src/state/publications/publicationsSelectors';
import { devicesSelector } from 'src/state/devices/devicesSelectors';
import { PublicationsPagination } from 'src/public/components/homepage/HomepagePublications/PublicationsPagination';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  position: sticky;
  top: 100px;
  background: ${colors.itpGrey};

  display: grid;
  grid-row-gap: 1rem;

  ${media.w.min.px768(css`
    grid-template-columns: 12.5rem 1fr;
    grid-column-gap: 2rem;
  `)}

  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const StyledSelect = styled(Select)`
  .react-select__control {
    cursor: pointer;
    background: ${colors.itpGrey};
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: ${colors.white};
    border-radius: 0;
  }

  .react-select__single-value {
    color: ${colors.white};
  }

  .react-select__input-container {
    color: ${colors.white};
  }

  .react-select__menu {
    background: ${colors.itpGrey};
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: ${colors.white};
    border-radius: 0;
  }

  .react-select__option {
    cursor: pointer;

    &.react-select__option--is-focused {
      background-color: rgba(255, 255, 255, 0.2);
    }

    &.react-select__option--is-selected {
      background-color: ${colors.itpGold};
      color: ${colors.itpGrey};
    }
  }

  .react-select__indicator:hover {
    color: ${colors.white};
  }
`;

export const PublicationFilters: FC = () => {
  const dispatch = useDispatch();

  const onOptionChange = useCallback(
    (option) => {
      document?.querySelector('#publications')?.scrollIntoView();
      dispatch(
        publicationsActions.setFilters({
          device: option?.value,
        })
      );
    },
    [dispatch]
  );

  const filters = useSelector(publicationsFiltersSelector);

  const devices = useSelector(devicesSelector);
  const options = useMemo(
    () => devices?.map((a) => ({ value: a.id, label: a.name })),
    [devices]
  );

  return (
    <Container>
      {options?.length > 0 && (
        <StyledSelect
          className="react-select-container"
          classNamePrefix="react-select"
          value={options.find((a) => a.value === filters.device)}
          onChange={onOptionChange}
          isClearable
          options={options}
          placeholder="Wybierz urządzenie..."
        />
      )}
      <PublicationsPagination />
    </Container>
  );
};
