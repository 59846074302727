import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';
import { Button, CleanButton } from 'src/common/components/Button';
import { CheckboxControl } from 'src/common/components/CheckboxControl';
import {
  ContactRegistrationFormModel,
  mapToContactRegistrationPayload,
  specializationsMenuItems,
} from 'src/common/models/registration';
import { SelectControl } from 'src/common/components/SelectControl';
import { registrationEmailSelector } from 'src/state/registration/registrationSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/pipeable';
import {
  contactRegistrationActions,
  registrationActions,
} from 'src/state/registration/registrationActions';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { SimpleLink } from 'src/common/components/SimpleLink';

const CheckboxFormItem = styled.div`
  a {
    color: ${colors.white};
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 14.6875rem;
`;

const StyledButton = styled(Button)`
  justify-content: space-around;
  margin-top: 1.25rem;
  width: 100%;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`;

const BackButton = styled(CleanButton)`
  font-size: ${typography.fontSizeBase};
  line-height: 1.125rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    padding-left: 0.75rem;
  }
`;

const validationSchema = object().shape({
  consent: boolean().oneOf([true, false], 'Nieprawidłowa wartość'),
  specialization: string().required('Pole "specjalizacja" jest wymagane'),
  email: string().required('Pole wymagane').email('Niepoprawny adres e-mail'),
  gdpr: boolean().oneOf([true], 'Pole wymagane'),
  mdTitle: boolean().oneOf([true], 'Pole wymagane'),
  name: string().required('Pole "imię i nazwisko" jest wymagane'),
  partners: boolean().oneOf([true, false], 'Nieprawidłowa wartość'),
  city: string().required('Pole "miasto" jest wymagane'),
  institution: string().required('Pole "nazwa gabinetu" jest wymagane'),
});

export const HomepageRegistrationModalRegistrationScreen: FC = () => {
  const dispatch = useDispatch();
  const email = useSelector(registrationEmailSelector);
  const [isProcessing, setIsProcessing] = useState(false);

  useRequestEffect(contactRegistrationActions.request, {
    onError: () => setIsProcessing(false),
  });

  const handleBack = useCallback(
    () => dispatch(registrationActions.goToInitStep()),
    []
  );

  const initialValues = useMemo<ContactRegistrationFormModel>(
    () => ({
      email,
      consent: false,
      city: '',
      institution: '',
      gdpr: false,
      mdTitle: false,
      name: '',
      partners: false,
      specialization: '',
    }),
    [email]
  );

  return (
    <Container>
      <BackButton onClick={handleBack}>
        <ChevronIcon direction="left" size={10} />
        <span>Wstecz</span>
      </BackButton>
      <Title>Zarejestruj się</Title>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setIsProcessing(true);
          pipe(
            values,
            mapToContactRegistrationPayload,
            contactRegistrationActions.request,
            dispatch
          );
          setSubmitting(false);
        }}
      >
        <Form autoComplete="off" noValidate>
          <FormItem name="email">
            <TextControl label="Adres e-mail*" name="email" type="email" />
          </FormItem>
          <FormItem name="name">
            <TextControl label="Imię i nazwisko*" name="name" type="text" />
          </FormItem>
          <FormItem name="specialization">
            <SelectControl
              items={specializationsMenuItems}
              label="Specjalizacja*"
              name="specialization"
            />
          </FormItem>
          <FormItem name="institution">
            <TextControl
              label="Nazwa gabinetu*"
              name="institution"
              type="text"
            />
          </FormItem>
          <FormItem name="city">
            <TextControl label="Miasto*" name="city" type="text" />
          </FormItem>
          <CheckboxFormItem>
            <FormItem name="gdpr">
              <CheckboxControl
                name="gdpr"
                label={
                  <>
                    Zapoznałem/am się i&nbsp;akceptuję{' '}
                    <SimpleLink
                      href="/regulamin"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      Regulamin
                    </SimpleLink>
                    .
                  </>
                }
              />
            </FormItem>
          </CheckboxFormItem>
          <CheckboxFormItem>
            <FormItem name="mdTitle">
              <CheckboxControl
                name="mdTitle"
                label={
                  <>
                    Potwierdzam, że jestem profesjonalistą z zakresu medycyny
                    estetycznej, tym samym mogę korzystać z zasobów Platformy
                    ITP Academy zgodnie z
                    <SimpleLink
                      href="/regulamin"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      Regulaminem
                    </SimpleLink>
                    .
                  </>
                }
              />
            </FormItem>
          </CheckboxFormItem>
          <CheckboxFormItem>
            <FormItem name="consent">
              <CheckboxControl
                name="consent"
                label={
                  <>
                    Wyrażam zgodę na otrzymywanie na podany adres e-mail od ITP
                    S.A. z&nbsp;siedzibą w&nbsp;Warszawie (02-672), ul.
                    Domaniewska 37, Newslettera, w&nbsp;tym informacji o
                    promocjach, aktywnościach itp., zgodnie{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                      z&nbsp;
                      <SimpleLink
                        href="/regulamin"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                      >
                        Regulaminem
                      </SimpleLink>
                      .
                    </span>
                  </>
                }
              />
            </FormItem>
          </CheckboxFormItem>
          <CheckboxFormItem>
            <FormItem name="partners">
              <CheckboxControl
                name="partners"
                label={
                  <>
                    Wyrażam zgodę na otrzymywanie na podany adres e-mail od
                    partnerów ITP tj. spółek z grupy kapitałowej MATEX LAB
                    (m.in. Matex Lab S.A., Matex Lab Switzerland S.A.,
                    <SimpleLink
                      href="https://www.neauvia.com/contact-us"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      https://www.neauvia.com/contact-us
                    </SimpleLink>
                    ), Newslettera, zgodnie z&nbsp;zasadami wskazanymi na:
                    <SimpleLink
                      href="https://www.iubenda.com/privacy-policy/36967656"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      https://www.iubenda.com/privacy-policy/36967656
                    </SimpleLink>
                  </>
                }
              />
            </FormItem>
          </CheckboxFormItem>

          <StyledButton disabled={isProcessing} kind="primary" type="submit">
            {!isProcessing && <>Zarejestruj się</>}

            {isProcessing && <LoadingIcon size={32} />}
          </StyledButton>

          <p style={{ marginTop: '1rem' }}>
            Administratorem danych osobowych podanych w&nbsp;formularzu jest ITP
            S.A. z&nbsp;siedzibą w Warszawie (02–672), ul. Domaniewska 37,
            e-mail:{' '}
            <SimpleLink
              href="mailto:iod@itpsa.pl"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              iod@itpsa.pl
            </SimpleLink>
            . Dane będziemy przetwarzać w&nbsp;celu rejestracji do naszej
            społeczności i&nbsp;dalszego umożliwienia korzystania Pani/Panu
            z&nbsp;www.itpacademy.pl, do celów marketingowych oraz
            w&nbsp;przypadku udzielenia na to osobnej zgody – w&nbsp;celu
            wysyłki zamówionego Newslettera. Ma Pan/i prawo w&nbsp;każdym czasie
            wycofać udzieloną zgodę na przesyłanie Newslettera, np. pisząc do
            nas na adres e-mail:{' '}
            <SimpleLink
              href="mailto:marketing@itpsa.pl"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              marketing@itpsa.pl
            </SimpleLink>
            . Przysługują Pani/Panu prawa przyznane przez RODO,
            w&nbsp;szczególności prawo do zapomnienia, sprostowania,
            ograniczenia danych, dostępu do danych, do ich przeniesienia. Więcej
            na temat przetwarzania przez ITP danych osobowych można znaleźć
            w&nbsp;naszej{' '}
            <SimpleLink
              href="/polityka-prywatnosci"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Polityce&nbsp;Prywatności
            </SimpleLink>
            .
          </p>
        </Form>
      </Formik>
    </Container>
  );
};
