import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { DevicesState } from 'src/state/devices/devicesReducer';

export const devicesStateSelector = (state: RootState) => state.devices;

export const devicesSelector = createSelector(
  devicesStateSelector,
  (state: DevicesState) => state.devices
);
