import React, { FC, useState } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';
import { media } from 'src/config/breakpoints';
import { CleanButton } from 'src/common/components/Button';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { colors } from 'src/config/colors';
import ReactMarkdown from 'react-markdown';

const Avatar = styled.div`
  flex: 0 0 2.375rem;
  margin-right: 0.75rem;
  margin-top: 0.5rem;

  img {
    border-radius: 100%;
    width: 100%;
  }
`;

const Container = styled.div`
  ${media.w.min.px768(css`
    display: none;
  `)}
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const Duration = styled.div`
  color: ${colors.itpGold};
  font-size: 0.625rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  display: flex;

  div {
    display: flex;
    margin-left: 0.75rem;

    span {
      color: ${colors.grey4};
      margin-left: 0.25rem;
    }
  }
`;

const Footer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 0.125rem;
`;

const Header = styled.div`
  display: flex;
  margin-top: 0.4375rem;
`;

const Info = styled.div`
  margin-top: 1.125rem;
`;

const InfoButton = styled(CleanButton)`
  align-items: center;
  color: ${({ theme }) => (theme.open ? colors.grey1 : colors.grey105)};
  display: flex;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-right: 0.75rem;
  text-transform: uppercase;

  svg {
    color: ${({ theme }) => (theme.open ? colors.itpGold : colors.grey409)};
    display: block;
    margin-left: 0.4375rem;
  }
`;

const CloseButton = styled(InfoButton)`
  color: ${colors.grey1};

  svg {
    color: ${colors.itpGold};
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
`;

const Name = styled.div`
  color: ${colors.grey105};
  font-size: 0.625rem;
  line-height: 0.75rem;
`;

const Speaker = styled.div`
  color: ${colors.grey1};
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 2.375rem;

  strong {
    color: ${colors.itpGold};
    display: block;
    font-weight: 700;
  }
`;

const Tags = styled.div`
  color: ${colors.grey108};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-top: 2.375rem;

  span {
    display: inline-block;
    margin-right: 1.25rem;
    white-space: nowrap;
  }
`;

const Text = styled.div`
  color: ${colors.grey1};
  font-size: 0.875rem;
  line-height: 1.3125rem;

  p {
    margin: 0 0 1.3125rem;

    &:last-child {
      margin: 0;
    }
  }
`;

const Title = styled.div`
  color: ${colors.grey1};
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.75rem;
  text-transform: uppercase;
`;

export interface VideoCardMobileContentProps {
  item: VideoAcademyItem;
}

export const VideoCardMobileContent: FC<VideoCardMobileContentProps> = ({
  item,
}) => {
  const [open, toggleOpen] = useState(false);

  return (
    <ThemeProvider theme={{ open }}>
      <Container>
        <Header>
          {item.speakers && item.speakers.length > 0 && (
            <Avatar>
              {item.speakers[0].avatar && (
                <img
                  src={item.speakers[0].avatar.url}
                  alt={item.speakers[0].name}
                />
              )}
            </Avatar>
          )}

          <Content>
            <Duration>
              {item.duration?.label}
              {item.languages?.map((language) => (
                <div key={language.id}>
                  <img src={language.icon?.url} />
                  <span>{language.label}</span>
                </div>
              ))}
            </Duration>

            <Title>{item.title}</Title>

            <Footer>
              <Name>
                {item.speakers &&
                  item.speakers.length > 0 &&
                  item.speakers[0].name}
              </Name>

              <InfoButton onClick={() => toggleOpen(!open)}>
                INFO
                <ChevronIcon direction={open ? 'down' : 'up'} size={11} />
              </InfoButton>
            </Footer>
          </Content>
        </Header>

        {open && (
          <Info>
            <Text>
              <ReactMarkdown>{item.description}</ReactMarkdown>
            </Text>

            {item.speakers?.map((speaker) => (
              <Speaker key={speaker.id}>
                <strong>{speaker.name}</strong>
                {speaker.description}
              </Speaker>
            ))}

            <Tags>
              {item.tags?.map((tag) => (
                <span key={tag.id}>#{tag.label}</span>
              ))}
            </Tags>

            <CloseButtonContainer>
              <CloseButton onClick={() => toggleOpen(false)}>
                ukryj
                <ChevronIcon direction="up" size={11} />
              </CloseButton>
            </CloseButtonContainer>
          </Info>
        )}
      </Container>
    </ThemeProvider>
  );
};
