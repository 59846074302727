import React, { FC, useCallback } from 'react';
import { Portal } from 'src/common/components/Portal';
import { Modal } from 'src/common/components/Modal';
import { useDispatch } from 'react-redux';
import { registrationActions } from 'src/state/registration/registrationActions';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { HomepageRegistrationModalSuccessScreen } from 'src/public/components/homepage/HomepageRegistrationModal/HomepageRegistrationModalSuccessScreen';

const Container = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.w.min.px768(css`
    display: grid;
    grid-template-areas: 'header . content . .';
    grid-template-columns: 225px 1fr max-content 1fr 225px;
  `)}

  ${media.w.min.px992(css`
    padding: 2.5rem 0 3.125rem;
  `)}

  ${media.w.min.px992(css`
    padding: 7.1875rem 0;
  `)}
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  grid-area: content;
  width: 100%;
`;

const Header = styled.div`
  grid-area: header;
`;

const Title = styled.div`
  font-size: 1.6875rem;
  font-weight: bold;
  line-height: 2.0625rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const HomepageNewsletterModal: FC = () => {
  const dispatch = useDispatch();

  const handleClose = useCallback(
    () => dispatch(registrationActions.toggleNewsletter(false)),
    []
  );

  return (
    <Portal>
      <Modal onClose={handleClose}>
        <Container>
          <Header>
            <Title>Dołącz do naszej społeczności</Title>
          </Header>

          <Content>
            <HomepageRegistrationModalSuccessScreen
              onClose={handleClose}
              content={
                <>
                  GRATULACJE!
                  <br />
                  Zostałeś pomyślnie zapisany do Newslettera
                </>
              }
              buttonLabel="WRÓĆ"
            />
          </Content>
        </Container>
      </Modal>
    </Portal>
  );
};
