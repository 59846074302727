import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const ScrollOffset = styled.span`
  height: 0;
  position: absolute;
  top: -3.5rem;

  ${media.w.min.px992(css`
    top: -5.5rem;
  `)}
`;
