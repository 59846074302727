import React, { FC, useCallback } from 'react';
import { Portal } from 'src/common/components/Portal';
import { Modal } from 'src/common/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { registrationActions } from 'src/state/registration/registrationActions';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { HomepageRegistrationModalEmailScreen } from 'src/public/components/homepage/HomepageRegistrationModal/HomepageRegistrationModalEmailScreen';
import { registrationCurrentStepSelector } from 'src/state/registration/registrationSelectors';
import { RegistrationSteps } from 'src/state/registration/registrationReducer';
import { media } from 'src/config/breakpoints';
import { HomepageRegistrationModalRegistrationScreen } from 'src/public/components/homepage/HomepageRegistrationModal/HomepageRegistrationModalRegistrationScreen';
import { HomepageRegistrationModalSuccessScreen } from 'src/public/components/homepage/HomepageRegistrationModal/HomepageRegistrationModalSuccessScreen';
import { SimpleLink } from 'src/common/components/SimpleLink';

const Container = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.w.min.px768(css`
    display: grid;
    grid-template-areas: 'header . content . .';
    grid-template-columns: 225px 1fr max-content 1fr 225px;
  `)}

  ${media.w.min.px992(css`
    padding: 2.5rem 0 3.125rem;
  `)}

  ${media.w.min.px992(css`
    padding: 7.1875rem 0;
  `)}
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  grid-area: content;
  width: 100%;
`;

const Header = styled.div`
  grid-area: header;
`;

const Title = styled.div`
  font-size: 1.6875rem;
  font-weight: bold;
  line-height: 2.0625rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const HomepageRegistrationModal: FC = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(registrationCurrentStepSelector);

  const handleClose = useCallback(
    () => dispatch(registrationActions.toggleModal(false)),
    []
  );

  const handleCloseReset = useCallback(() => {
    dispatch(registrationActions.toggleModal(false));
    dispatch(registrationActions.goToInitStep());
  }, []);

  return (
    <Portal>
      <Modal onClose={handleClose}>
        <Container>
          <Header>
            <Title>Dołącz do naszej społeczności</Title>
          </Header>

          <Content>
            {currentStep === RegistrationSteps.Init && (
              <HomepageRegistrationModalEmailScreen />
            )}

            {currentStep === RegistrationSteps.Registration && (
              <HomepageRegistrationModalRegistrationScreen />
            )}

            {currentStep === RegistrationSteps.Exists && (
              <HomepageRegistrationModalSuccessScreen
                onClose={handleClose}
                content={<>Zostałeś zalogowany!</>}
              />
            )}

            {currentStep === RegistrationSteps.Success && (
              <HomepageRegistrationModalSuccessScreen
                onClose={handleClose}
                content={
                  <>
                    GRATULACJE!
                    <br />
                    Zarejestrowałeś się pomyślnie
                  </>
                }
              />
            )}

            {currentStep === RegistrationSteps.Inactive && (
              <HomepageRegistrationModalSuccessScreen
                onClose={handleCloseReset}
                buttonLabel="OK"
                content={
                  <>
                    TWOJE KONTO ZOSTAŁO UTWORZONE, ALE NIE JEST JESZCZE AKTYWNE.
                    WRÓĆ ZA CHWILĘ LUB SKONTAKTUJ SIĘ Z&nbsp;NAMI ZA POMOCĄ
                    MAILA{' '}
                    <SimpleLink
                      href="mailto:itpacademy@itpsa.pl"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      itpacademy@itpsa.pl
                    </SimpleLink>
                    , JEŚLI AKTYWACJA TRWA ZBYT DŁUGO.
                  </>
                }
              />
            )}
          </Content>
        </Container>
      </Modal>
    </Portal>
  );
};
