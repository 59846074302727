import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { videoAcademyFeaturedVideosSelector } from 'src/state/videoAcademy/videoAcademySelectors';
import { getFeaturedActions } from 'src/state/videoAcademy/videoAcademyActions';
import { VideosSwiper } from 'src/common/components/VideosSwiper';
import { InnerContainer } from 'src/common/components/Containers';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  z-index: 100;

  // (View width - (container width + padding)) / 2
  ${media.w.min.px992(css`
    left: calc((100vw - 57rem) / 2);
  `)}

  ${media.w.min.px1200(css`
    left: calc((100vw - 75rem) / 2);
  `)}

  ${media.w.min.px1366(css`
    left: calc((100vw - 86.5rem) / 2);
  `)}
`;

export const HomepageFeaturedVideos: FC = () => {
  const dispatch = useDispatch();
  const featured = useSelector(videoAcademyFeaturedVideosSelector);

  useEffect(() => {
    dispatch(getFeaturedActions.request());
  }, [dispatch]);

  return (
    <Container>
      <InnerContainer style={{ position: 'relative', zIndex: 2 }}>
        <VideosSwiper
          videos={featured}
          title="POLECANE SZKOLENIA ON-LINE / WEBINARY"
        />
      </InnerContainer>
    </Container>
  );
};
