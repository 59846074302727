import React, { FC } from 'react';
import { Publication } from 'src/common/models/publications';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { PublicationDownloadButton } from 'src/public/components/homepage/HomepagePublications/PublicationDownloadButton';
import { media } from 'src/config/breakpoints';

const Container = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;

  display: grid;
  grid-template-areas: 'devices' 'title' 'download';
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 1rem;

  ${media.w.min.px1200(css`
    grid-template-areas: 'devices title' 'devices download';
    grid-template-columns: 1fr 65%;
    grid-template-rows: auto 1fr;
    grid-column-gap: 2.5rem;
  `)}

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.h4`
  grid-area: title;
  font-style: normal;
  font-weight: 510;
  font-size: 1.3125rem;
  line-height: 1.75rem;
  margin: 0;
`;

const Devices = styled.div`
  grid-area: devices;
  display: flex;
  flex-wrap: wrap;
  align-self: start;
  align-items: start;
  gap: 0.5rem;
`;

const DeviceTag = styled.span`
  background-color: ${colors.itpGold};
  color: ${colors.itpGrey};
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
`;

const DownloadContainer = styled.div`
  grid-area: download;
`;

export interface PublicationItemProps {
  publication: Publication;
}

export const PublicationItem: FC<PublicationItemProps> = ({ publication }) => (
  <Container>
    <Devices>
      {publication.devices.map((a) => (
        <DeviceTag key={a.id}>{a.name}</DeviceTag>
      ))}
    </Devices>
    <Title>{publication.title}</Title>
    <DownloadContainer>
      <PublicationDownloadButton publication={publication} />
    </DownloadContainer>
  </Container>
);
