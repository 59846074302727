import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { HeaderInfoContent } from 'src/public/components/homepage/HeaderInfoContent';

const Wrapper = styled.div`
  ${media.w.max.px992(css`
    display: none;
  `)}
`;

export const HeaderInfo: FC = () => {
  return (
    <Wrapper>
      <HeaderInfoContent />
    </Wrapper>
  );
};
