import React, { FC } from 'react';
import { Field } from 'formik';
import { TextField } from 'src/common/components/TextField';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import { TextFieldProps } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

export type TextControlProps = {
  footer?: boolean;
  name: string;
  validate?: FieldValidator;
} & TextFieldProps;

export const TextControl: FC<TextControlProps> = ({
  name,
  validate,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: TextControlProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { name, value, onBlur, onChange },
      meta,
    }: FieldProps<string>) => (
      <Container>
        <TextField
          error={!!(meta.error && meta.touched)}
          name={name}
          value={value}
          onChange={(v) => {
            onChange(v);
            $onChange?.(v);
          }}
          onBlur={(e) => {
            onBlur(e);
            $onBlur?.(e);
          }}
          {...restProps}
        />
      </Container>
    )}
  </Field>
);

TextControl.displayName = 'TextControl';
