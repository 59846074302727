import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { ScrollOffset } from 'src/common/components/ScrollOffset';
import { useDispatch, useSelector } from 'react-redux';
import { publicationsItemsSelector } from 'src/state/publications/publicationsSelectors';
import {
  getPublicationsActions,
  getPublicationsCountActions,
} from 'src/state/publications/publicationsActions';
import { PublicationItem } from 'src/public/components/homepage/HomepagePublications/PublicationItem';
import { PublicationFilters } from 'src/public/components/homepage/HomepagePublications/PublicationFilters';
import { getDevicesActions } from 'src/state/devices/devicesActions';

const Wrapper = styled.div`
  background: ${colors.itpGrey};
  color: ${colors.white};
  position: relative;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  padding: 3.5625rem 2.1875rem 5.25rem 1.75rem;
  width: 85.375rem;

  ${media.w.min.px768(css`
    padding: 2.625rem 6.1875rem 3.5rem 1.8125rem;
  `)}

  ${media.w.min.px992(css`
    padding: 5.1875rem 7.5rem 6.6875rem 2.5rem;
  `)}

  ${media.w.min.px1200(css`
    padding: 5.1875rem 7.5rem 6.6875rem 4.625rem;
  `)}
`;

const Title = styled.div`
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;

  ${media.w.min.px768(css`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  `)}

  ${media.w.min.px992(css`
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  `)}

  &::after {
    background-color: currentColor;
    content: '';
    display: block;
    height: 0.25rem;
    margin-top: -0.125rem;
    width: 8.125rem;

    ${media.w.min.px768(css`
      width: 3.75rem;
    `)}

    ${media.w.min.px992(css`
      margin-top: 0.3125rem;
    `)}
  }
`;

const PublicationsContainer = styled.div`
  ${media.w.min.px992(css`
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
  `)}
`;

export const HomepagePublications: FC = () => {
  const dispatch = useDispatch();

  const publications = useSelector(publicationsItemsSelector);

  useEffect(() => {
    dispatch(getDevicesActions.request());
    dispatch(getPublicationsActions.request());
    dispatch(getPublicationsCountActions.request());
  }, [dispatch]);

  return (
    <Wrapper>
      <ScrollOffset id="publications" />

      <Container>
        <Title>Publikacje medyczne</Title>
        <PublicationFilters />
        <PublicationsContainer>
          {publications?.map((a) => (
            <PublicationItem publication={a} key={a.id} />
          ))}
        </PublicationsContainer>
      </Container>
    </Wrapper>
  );
};
