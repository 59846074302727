import { createRootRoute } from '@summer/react-kit';

/*
 * For some reason using empty createRootRoute causes gatsby to throw warning form its Link component:
 * "External link  was detected in a Link component. Use the Link component only for internal links. See: https://gatsby.dev/internal-links ..."
 */

export const HomepageRoute = '/';

export const itpContactRoute = 'https://itpsa.pl/contact';
