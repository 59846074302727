import { FunctionComponentElement, ReactElement, ReactNode } from 'react';

export function isReactElement(children: ReactNode): children is ReactElement {
  return (
    children != null &&
    children.hasOwnProperty('type') &&
    children.hasOwnProperty('props')
  );
}

export function isFunctionComponentElement(
  children: ReactNode
): children is FunctionComponentElement<any> {
  return isReactElement(children) && children.hasOwnProperty('ref');
}
