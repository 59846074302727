import React, { FC } from 'react';
import styled from 'styled-components';
import PlayIconSvg from 'src/assets/images/play-icon.svg';
import { colors } from 'src/config/colors';
import { truncateText } from 'src/lib/truncateText';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  background: ${colors.black09};
  display: flex;
  opacity: 0.8;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 0 1rem;
  max-height: 3.25rem;
  height: 100%;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.875rem;
  transition: max-height 0.5s;
`;

const PlayIcon = styled(PlayIconSvg)`
  display: block;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  margin-right: 0.625rem;
  transition: min-height 0.5s;
`;

export const VideoTitledThumbnailFooter: FC<{ title: string }> = ({
  title,
}) => {
  return (
    <Wrapper className="footer-wrapper">
      <PlayIcon className="footer-icon" />
      {truncateText(title, 55)}
    </Wrapper>
  );
};
