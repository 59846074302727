import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { FormItem } from 'src/common/components/FormItem';
import { TextControl } from 'src/common/components/TextControl';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Button } from 'src/common/components/Button';
import { contactExistsActions } from 'src/state/registration/registrationActions';
import { useDispatch } from 'react-redux';
import {
  ContactExistsFormModel,
  mapToContactExistsPayload,
} from 'src/common/models/registration';
import { pipe } from 'fp-ts/lib/pipeable';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { useRequestEffect } from 'src/lib/useRequestEffect';

const Container = styled.div`
  margin: auto;
  max-width: 14.6875rem;
`;

const StyledButton = styled(Button)`
  justify-content: space-around;
  margin-top: 1.25rem;
  width: 100%;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`;

const initialValues: ContactExistsFormModel = {
  email: '',
};

const validationSchema = object().shape({
  email: string().required('wymagane').email('Niepoprawny adres e-mail'),
});

export const HomepageRegistrationModalEmailScreen: FC = () => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  useRequestEffect(contactExistsActions.request, {
    onError: () => setIsProcessing(false),
  });

  return (
    <Container>
      <Title>podaj swój adres e-mail</Title>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setIsProcessing(true);
          pipe(
            values,
            mapToContactExistsPayload,
            contactExistsActions.request,
            dispatch
          );
          setSubmitting(false);
        }}
      >
        <Form autoComplete="off" noValidate>
          <FormItem name="email">
            <TextControl label="Adres e-mail*" name="email" type="email" />
          </FormItem>

          <StyledButton disabled={isProcessing} kind="primary" type="submit">
            {!isProcessing && <>zaloguj</>}

            {isProcessing && <LoadingIcon size={32} />}
          </StyledButton>
        </Form>
      </Formik>
    </Container>
  );
};
