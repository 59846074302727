import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import aboutUsImage from 'src/assets/images/about-us-image.jpg';
import { media } from 'src/config/breakpoints';
import { ScrollOffset } from 'src/common/components/ScrollOffset';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { itpContactRoute } from 'src/public/publicRoutes';

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 4.125rem 1.375rem 4.25rem;
  text-align: center;
  width: 85.375rem;

  ${media.w.min.px768(css`
    align-items: flex-start;
    display: flex;
    padding: 2.5rem 1.375rem 0 2.125rem;
    text-align: left;
  `)}

  ${media.w.min.px992(css`
    padding: 4.625rem 2.5rem 0;
  `)}

  ${media.w.min.px1200(css`
    padding: 4.625rem 4.625rem 0;
  `)}
`;

const Content = styled.div`
  margin: 2.75rem auto 0;
  max-width: 16.25rem;

  ${media.w.min.px768(css`
    margin: 0;
    max-width: none;
    padding-bottom: 2.5rem;
    align-self: center;
  `)}

  ${media.w.min.px992(css`
    padding-bottom: 4.625rem;
  `)}

  ${media.w.min.px1200(css`
    padding-bottom: 4.625rem;
  `)}
`;

const Image = styled.div`
  flex: 0 0 259px;
  margin: 0 1.8125rem -1.8125rem 2.5rem;

  ${media.w.max.px768(css`
    display: none;
  `)}

  ${media.w.min.px992(css`
    flex-basis: 22.9375rem;
    margin: 0 5.5rem -2.8125rem;
  `)}
  
  ${media.w.min.px1200(css`
    flex-basis: 27.375rem;
    margin: 0 5.75rem -5.625rem;
  `)}
  
  img {
    display: block;
    height: 35.5625rem;
    object-fit: cover;
    object-position: center;
    width: 100%;

    ${media.w.min.px992(css`
      height: auto;
    `)}
  }
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-top: 1.625rem;

  ${media.w.min.px992(css`
    margin-top: 0;
    font-size: 1.0625rem;
    line-height: 1.5rem;
  `)}
  strong {
    font-weight: 700;
  }
`;

const TitleWrapper = styled.div`
  ${media.w.min.px768(css`
    padding-bottom: 2.5rem;
    align-self: center;
  `)}

  ${media.w.min.px992(css`
    padding-bottom: 4.625rem;
  `)}

  ${media.w.min.px1200(css`
    padding-bottom: 4.625rem;
  `)}
`;

const Title = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  padding-bottom: 0.1875rem;
  position: relative;
  text-transform: uppercase;

  &::after {
    border: 0.125rem solid ${colors.itpGrey};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    width: 3.75rem;
  }
`;

const Wrapper = styled.div`
  background: ${colors.itpLightGrey};
  color: ${colors.itpGrey};
  position: relative;
`;

const ContactButton = styled(CleanButton)`
  font-weight: 700;
`;

export const HomepageAboutUs: FC = () => {
  return (
    <Wrapper>
      <ScrollOffset id="about-us" />

      <Container>
        <TitleWrapper>
          <Title>O&nbsp;NAS</Title>
        </TitleWrapper>

        <Image>
          <img src={aboutUsImage} alt="" />
        </Image>

        <Content>
          <Text>
            Na platformie znajdziesz materiały video oraz publikacje medyczne,
            które przybliżą Ci nasze technologie i&nbsp;urządzenia.
            <br />
            <br />
            Poznasz doświadczenia innych specjalistów i&nbsp;zweryfikujesz swoją
            wiedzę o technologiach i&nbsp;zabiegach, by jeszcze lepiej dbać
            o&nbsp;swojego pacjenta i&nbsp;stale podnosić jakość oferowanych
            przez Ciebie usług.
            <br />
            <br />
            Masz pytania – &nbsp;
            <ContactButton as="a" href={itpContactRoute} target="_blank">
              skontaktuj się z nami!
            </ContactButton>
          </Text>
        </Content>
      </Container>
    </Wrapper>
  );
};
