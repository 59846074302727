import React, { FC, useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { registrationActions } from 'src/state/registration/registrationActions';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';
import { shouldSeeRestrictedContentSelector } from 'src/state/registration/registrationSelectors';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { VideoTitledThumbnail } from 'src/common/components/VideoTitledThumbnail';
import ChevronRightThin from 'src/assets/images/chevron-right-thin.svg';
import ChevronLeftThin from 'src/assets/images/chevron-left-thin.svg';
import { NavigationOptions } from 'swiper/types/components/navigation';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { navigate } from 'gatsby';

const Item = styled.a`
  color: ${colors.white};
  cursor: pointer;
  display: block;
  position: relative;
  width: 14.375rem;
  height: 8.125rem;
  display: flex;

  ${media.h.max.px640(css`
    width: 13rem;
    height: 7rem;
  `)}

  ${media.w.min.px992(css`
    height: 9.3125rem;
    width: 16.5rem;
  `)}
`;

const StyledSwiper = styled(Swiper)`
  position: relative;

  .swiper-slide {
    backface-visibility: hidden;
    width: auto;
  }

  .swiper-button-prev {
    left: 0;
    width: 4.0625rem;

    &::after {
      content: '';
    }

    ${media.w.max.px768(css`
      display: none;
    `)}
  }

  .swiper-button-next {
    right: 0;
    width: 4.0625rem;

    &::after {
      content: '';
    }

    ${media.w.max.px768(css`
      display: none;
    `)}
  }

  .swiper-button-disabled {
    display: none;
  }
`;

const SwiperButtonWrapper = styled.div`
  background: ${colors.black05};
  height: 9.3125rem;
  width: 4.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-height: 34px;
    max-width: 17px;
  }
`;

const Title = styled.div`
  color: ${colors.white};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 0.875rem;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  ${media.h.max.px640(css`
    font-size: 0.8rem;
  `)}

  ${media.w.min.px992(css`
    font-weight: 700;
    line-height: 2.25rem;
  `)}
`;

const Loader = styled.div`
  align-items: center;
  color: ${colors.white};
  display: flex;
  height: 15.25rem;
  justify-content: center;
  text-align: center;
`;

SwiperCore.use([Scrollbar, Navigation]);

interface VideosSwiperProps {
  videos?: VideoAcademyItem[];
  title: string;
}

export const VideosSwiper: FC<VideosSwiperProps> = ({ videos, title }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const dispatch = useDispatch();
  const showRestrictedContent = useSelector(shouldSeeRestrictedContentSelector);

  const swiperOptions = useMemo<Swiper>(
    () => ({
      breakpoints: {
        [widthBreakpoints.px768]: {
          spaceBetween: 16,
        },
        [widthBreakpoints.px992]: {
          spaceBetween: 22,
        },
      },

      freeMode: true,
      freeModeMomentumRatio: 0.2,
      freeModeMomentumVelocityRatio: 0.2,
      freeModeSticky: true,
      slidesPerView: 'auto',
      spaceBetween: 15,
      watchOverflow: true,
    }),
    []
  );

  const handleClick = useCallback(
    (item: VideoAcademyItem) => {
      if (showRestrictedContent) {
        dispatch(registrationActions.setVideoItem(item));
        navigate(`/videos/${item.id}`);
      } else {
        dispatch(registrationActions.toggleModal(true));
      }
    },
    [showRestrictedContent]
  );

  return (
    <>
      <Title>{title}</Title>
      {videos ? (
        <StyledSwiper
          {...swiperOptions}
          onInit={(swiper) => {
            (swiper.params.navigation as NavigationOptions).prevEl =
              prevRef.current;
            (swiper.params.navigation as NavigationOptions).nextEl =
              nextRef.current;
            swiper.navigation.update();
          }}
        >
          {videos.map((item, i) => (
            <SwiperSlide key={i}>
              <Item onClick={() => handleClick(item)}>
                <VideoTitledThumbnail item={item} />
              </Item>
            </SwiperSlide>
          ))}
          <div ref={prevRef} className="swiper-button-prev">
            <SwiperButtonWrapper>
              <ChevronLeftThin />
            </SwiperButtonWrapper>
          </div>
          <div ref={nextRef} className="swiper-button-next">
            <SwiperButtonWrapper>
              <ChevronRightThin />
            </SwiperButtonWrapper>
          </div>
        </StyledSwiper>
      ) : (
        <Loader>
          <LoadingIcon size={64} />
        </Loader>
      )}
    </>
  );
};
