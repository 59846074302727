import React, { FC, useCallback, useEffect, useState } from 'react';
import { Portal } from 'src/common/components/Portal';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useKeyPress } from 'src/common/hooks/useKeyPress';
import { KEY_ESCAPE } from 'keycode-js';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import HamburgerCloseSvg from 'src/assets/images/hamburger-close.svg';
import { Button, CleanButton } from 'src/common/components/Button';
import { modalActions } from 'src/state/modal/modalActions';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import OverlayScrollbars from 'overlayscrollbars';
import { HomepageVideoAcademyCategoryFilter } from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyCategoryFilter';
import { videoAcademyActions } from 'src/state/videoAcademy/videoAcademyActions';
import {
  videoAcademyCategoriesSelector,
  videoAcademyDurationSelector,
  videoAcademyLanguageSelector,
  videoAcademySubjectSelector,
} from 'src/state/videoAcademy/videoAcademySelectors';
import { media } from 'src/config/breakpoints';
import ChevronLeftSvg from 'src/assets/images/chevron-left-icon.svg';

const BackButton = styled(CleanButton)`
  margin-right: 0.125rem;
  margin-top: 0.25rem;
  padding: 0.3125rem;

  ${media.w.min.px768(css`
    margin-right: 1.1875rem;
    margin-top: -0.25rem;
  `)}
`;

const ChevronLeft = styled(ChevronLeftSvg)`
  color: ${colors.white};
  height: 0.875rem;
`;

const CloseButton = styled(CleanButton)`
  position: absolute;
  right: 1.125rem;
  top: 1.25rem;
  z-index: 100;

  ${media.w.min.px768(css`
    right: 2.625rem;
    top: 2.5rem;
  `)}
  svg {
    display: block;
    height: 0.875rem;

    ${media.w.min.px768(css`
      height: 0.75rem;
    `)}
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.w.min.px768(css`
    justify-content: flex-start;
  `)}
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.75rem;
  width: 100%;

  ${media.w.min.px768(css`
    padding: 0 3.875rem;
  `)}
`;

const ResetFilters = styled(CleanButton)`
  align-items: center;
  color: ${colors.white};
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-left: 1.875rem;
  text-transform: uppercase;

  svg {
    height: 0.875rem;
    margin-right: 0.3125rem;
  }
`;

const ShowButton = styled(Button)`
  width: 10rem;
`;

const Title = styled.div`
  align-items: center;
  color: ${colors.white};
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-left: -1.375rem;
  padding: 4.5rem 0 1.25rem;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-left: -2.5rem;
    padding: 6.125rem 0 1.125rem;
    text-align: left;
  `)}
  span {
    ${media.w.max.px768(css`
      display: none;
    `)}
  }
`;

const VideoAcademyFilters = styled.div`
  flex: 0 0 auto;
  margin-bottom: 2.5rem;
  width: 100%;
`;

const Wrapper = styled.div`
  background: ${colors.itpGold};
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const scrollbarOptions: OverlayScrollbars.Options = {
  overflowBehavior: { x: 'hidden' },
};

export const HomepageVideoAcademyFiltersModal: FC = () => {
  const dispatch = useDispatch();
  const activeDuration = useSelector(videoAcademyDurationSelector);
  const activeLanguage = useSelector(videoAcademyLanguageSelector);
  const activeSubject = useSelector(videoAcademySubjectSelector);
  const categories = useSelector(videoAcademyCategoriesSelector);
  const [ref, setRef] = useState<OverlayScrollbarsComponent | null>(null);

  const showResetButton = activeDuration || activeLanguage || activeSubject;

  const handleClose = useCallback(() => {
    dispatch(modalActions.toggleFilters(false));
    dispatch(modalActions.toggleHamburger(false));
  }, []);

  const scrollTo = useCallback((id: string) => {
    document?.querySelector(`#${id}`)?.scrollIntoView({
      behavior: 'smooth',
    });

    handleClose();
  }, []);

  useEffect(() => {
    const viewport = ref?.osInstance()?.getElements().viewport;

    if (viewport) {
      disableBodyScroll(viewport);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [ref]);

  useKeyPress(KEY_ESCAPE, handleClose);

  return (
    <Portal>
      <Wrapper>
        <CloseButton onClick={handleClose}>
          <HamburgerCloseSvg />
        </CloseButton>

        <ScrollContainer options={scrollbarOptions} ref={setRef}>
          <ModalContent>
            <VideoAcademyFilters>
              <Title>
                <BackButton
                  onClick={() => dispatch(modalActions.toggleFilters(false))}
                >
                  <ChevronLeft />
                </BackButton>
                Szkolenia on-line / webinary
              </Title>

              <Filters>
                <HomepageVideoAcademyCategoryFilter
                  active={activeSubject}
                  action={videoAcademyActions.setSubject}
                  items={categories?.subjects}
                  label="Tematyka"
                  total={categories?.total}
                />

                <HomepageVideoAcademyCategoryFilter
                  active={activeDuration}
                  action={videoAcademyActions.setDuration}
                  items={categories?.durations}
                  label="Czas"
                  total={categories?.total}
                />

                <HomepageVideoAcademyCategoryFilter
                  active={activeLanguage}
                  action={videoAcademyActions.setLanguage}
                  items={categories?.languages}
                  label="Język"
                  total={categories?.total}
                />
              </Filters>

              <Footer>
                <ShowButton onClick={() => scrollTo('medical-education')}>
                  Filtruj
                </ShowButton>

                {showResetButton && (
                  <ResetFilters
                    onClick={() => dispatch(videoAcademyActions.clearFilters())}
                  >
                    <HamburgerCloseSvg />
                    resetuj filtry
                  </ResetFilters>
                )}
              </Footer>
            </VideoAcademyFilters>
          </ModalContent>
        </ScrollContainer>
      </Wrapper>
    </Portal>
  );
};
