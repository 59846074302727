export const specializationsMenuItems = [
  { label: 'Dermatologia', value: 'Dermatology' },
  { label: 'Medycyna estetyczna', value: 'Aesthetic medicine' },
  { label: 'Chirurgia plastyczna', value: 'Aesthetic surgery' },
  { label: 'Kosmetologia', value: 'Cosmetology' },
  { label: 'Inne (właściciel, manager, inwestor)', value: 'Other' },
];

export interface ContactExistsDTO {
  contact: { email: string; name: string } | null;
  active?: boolean;
}

export interface ContactExistsPayload {
  email: string;
}

export interface ContactExistsFormModel {
  email: string;
}

export interface ContactRegistrationDTO {
  created: boolean;
  exists: boolean;
}

export interface ContactRegistrationPayload {
  email: string;
  consent: boolean;
  city: string;
  institution: string;
  gdpr: boolean;
  mdTitle: boolean;
  name: string;
  partners: boolean;
  specialization: string;
}

export interface ContactRegistrationFormModel {
  email: string;
  consent: boolean;
  city: string;
  institution: string;
  gdpr: boolean;
  mdTitle: boolean;
  name: string;
  partners: boolean;
  specialization: string;
}

export interface RegistrationLeadPayload {
  email: string;
}

export interface RegistrationLeadFormModel {
  email: string;
}

export const mapToContactExistsPayload = ({
  email,
}: ContactExistsFormModel): ContactExistsPayload => ({
  email,
});

export const mapToContactRegistrationPayload = ({
  email,
  consent,
  city,
  institution,
  gdpr,
  mdTitle,
  name,
  partners,
  specialization,
}: ContactRegistrationFormModel): ContactRegistrationPayload => ({
  email,
  consent,
  city,
  institution,
  gdpr,
  name,
  mdTitle,
  partners,
  specialization,
});

export const mapToRegistrationLeadPayload = ({
  email,
}: RegistrationLeadFormModel): RegistrationLeadPayload => ({
  email,
});
