import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { Speaker } from 'src/common/models/videoAcademy';

const Avatar = styled.div`
  grid-area: avatar;
  padding-top: 0.3125rem;
  width: 2.75rem;

  img {
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  gap: 0 0.9375rem;
  grid-template-areas:
    'avatar name'
    'avatar description';
  grid-template-columns: 2.75rem 1fr;
  grid-template-rows: max-content 1fr;
  margin-bottom: 0.4375rem;
`;

const Description = styled.div`
  color: ${colors.grey1};
  font-size: 0.75rem;
  grid-area: description;
  line-height: 1.125rem;
`;

const Name = styled.div`
  color: ${colors.itpGold};
  font-size: 0.75rem;
  font-weight: 700;
  grid-area: name;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

export interface VideoCardSpeakerProps {
  speaker: Speaker;
}

export const VideoCardSpeaker: FC<VideoCardSpeakerProps> = ({ speaker }) => (
  <Container>
    <Avatar>
      {speaker.avatar && <img src={speaker.avatar.url} alt={speaker.name} />}
    </Avatar>

    <Name>{speaker.name}</Name>
    <Description>{speaker.description}</Description>
  </Container>
);
