import ModalCloseSvg from 'src/assets/images/modal-close.svg';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import itpAcademyLogo from 'src/assets/images/itpacademy-logo.svg';
import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { useKeyPress } from 'src/common/hooks/useKeyPress';
import { KEY_ESCAPE } from 'keycode-js';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import OverlayScrollbars from 'overlayscrollbars';
import HamburgerCloseSvg from 'src/assets/images/hamburger-close.svg';

const CloseDesktop = styled(ModalCloseSvg)`
  display: block;
  height: 2.3125rem;

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

const CloseMobile = styled(HamburgerCloseSvg)`
  display: block;
  height: 0.875rem;

  ${media.w.min.px768(css`
    height: 0.75rem;
  `)}

  ${media.w.min.px992(css`
    display: none;
  `)}
`;

const CloseButton = styled(CleanButton)`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  z-index: 100;

  ${media.w.min.px768(css`
    right: 2.8125rem;
    top: 3.125rem;
  `)}

  ${media.w.min.px992(css`
    right: 5.625rem;
    top: 6.25rem;
  `)}
`;

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 85.375rem;
`;

const Logo = styled.div`
  bottom: 0.5rem;
  position: absolute;
  right: 1rem;

  ${media.w.min.px992(css`
    bottom: 6.25rem;
    right: 5.625rem;
  `)}
  ${media.w.min.px1200(css`
    bottom: 6.875rem;
    right: 9.0625rem;
  `)}
`;

const ITPLogo = styled(itpAcademyLogo)`
  height: 3rem;
  width: 7.375rem;
`;

const ModalContent = styled.div`
  display: flex;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  width: 100%;

  ${media.w.min.px1200(css`
    padding-left: 14.0625rem;
    padding-right: 9.0625rem;
  `)}
`;

const Wrapper = styled.div`
  background: ${colors.itpGold09};
  bottom: 0;
  display: flex;
  left: 0;
  padding: 3.125rem 0 4.0625rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  ${media.w.min.px768(css`
    padding: 5.625rem 0 6.25rem;
  `)}

  ${media.w.min.px992(css`
    padding: 3.125rem 0;
  `)} @supports(backdrop-filter: blur(0.9375 rem)) {
    background: ${colors.itpGold09};
    backdrop-filter: blur(0.9375rem);
  }
`;

const scrollbarOptions: OverlayScrollbars.Options = {
  overflowBehavior: { x: 'hidden' },
};

export interface ModalProps {
  onClose: () => void;
}

export const Modal: FC<ModalProps> = ({ children, onClose }) => {
  const [ref, setRef] = useState<OverlayScrollbarsComponent | null>(null);
  useKeyPress(KEY_ESCAPE, onClose);

  useEffect(() => {
    const viewport = ref?.osInstance()?.getElements().viewport;

    if (viewport) {
      disableBodyScroll(viewport);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [ref]);

  return (
    <Wrapper>
      <CloseButton onClick={onClose}>
        <CloseDesktop />
        <CloseMobile />
      </CloseButton>

      <Container>
        <ScrollContainer options={scrollbarOptions} ref={setRef}>
          <ModalContent>{children}</ModalContent>
        </ScrollContainer>
      </Container>

      <Logo>
        <ITPLogo />
      </Logo>
    </Wrapper>
  );
};
