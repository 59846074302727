import React, { FC, useCallback, useEffect } from 'react';
import HamburgerSvg from 'src/assets/images/hamburger.svg';
import styled, { css } from 'styled-components';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { HeaderHamburgerModal } from 'src/public/components/homepage/Header/HeaderHamburgerModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalHamburgerSelector } from 'src/state/modal/modalSelectors';
import { modalActions } from 'src/state/modal/modalActions';
import { isRegisteredSelector } from 'src/state/registration/registrationSelectors';
import { registrationActions } from 'src/state/registration/registrationActions';

const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: end;

  ${media.w.min.px992(css`
    display: none;
  `)}
`;

const HamburgerButton = styled(CleanButton)`
  color: ${colors.white};
  display: block;
  padding: 1rem;

  svg {
    display: block;
    height: 1rem;
  }
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const AuthButton = styled(CleanButton)`
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
  color: white;
  padding: 0.375rem 0.5rem;
`;

export const HeaderMobile: FC = () => {
  const dispatch = useDispatch();
  const hamburgerModal = useSelector(modalHamburgerSelector);

  const isRegistered = useSelector(isRegisteredSelector);

  const openProcessModal = useCallback(() => {
    dispatch(modalActions.toggleHamburger(false));
    dispatch(registrationActions.goToInitStep());
    dispatch(registrationActions.toggleModal(true));
  }, []);

  const openRegistrationModal = useCallback(() => {
    dispatch(modalActions.toggleHamburger(false));
    dispatch(registrationActions.goToRegistrationStep());
    dispatch(registrationActions.toggleModal(true));
  }, []);

  const showHamburgerModal = useCallback(
    () => dispatch(modalActions.toggleHamburger(true)),
    []
  );

  useEffect(() => {
    const callback = () => {
      dispatch(modalActions.toggleHamburger(false));
    };
    const mediaQuery = window?.matchMedia(
      `(min-width: ${widthBreakpoints.px992}px)`
    );

    mediaQuery?.addListener(callback);

    return () => {
      mediaQuery?.removeListener(callback);
    };
  }, []);

  return (
    <Container>
      {!isRegistered && (
        <AuthContainer>
          <AuthButton onClick={openProcessModal}>Zaloguj</AuthButton>
          <AuthButton onClick={openRegistrationModal}>
            Zarejestruj się
          </AuthButton>
        </AuthContainer>
      )}

      <HamburgerButton onClick={showHamburgerModal}>
        <HamburgerSvg />
      </HamburgerButton>

      {hamburgerModal && <HeaderHamburgerModal />}
    </Container>
  );
};
