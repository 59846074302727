import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { itpContactRoute } from 'src/public/publicRoutes';

const Menu = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  width: 48rem;
  padding: 0 0 0 1.5rem;

  ${media.w.max.px992(css`
    display: none;
  `)}

  ${media.w.min.px1200(css`
    width: 55rem;
  `)}
`;

const MenuItem = styled(CleanButton)`
  align-items: center;
  color: ${colors.white08};
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  position: relative;
  text-transform: uppercase;

  &:hover {
    color: ${colors.white};

    &::after {
      content: '';
    }
  }

  &::after {
    background: ${colors.white};
    bottom: -0.4375rem;
    height: 0.125rem;
    left: 0;
    position: absolute;
    width: 80%;
  }
`;

const DesktopMenuItem = styled(MenuItem)<{ hidden?: boolean }>`
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

export const HeaderMenu: FC = () => {
  const handleClick = useCallback((id: string) => {
    document?.querySelector(`#${id}`)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <Menu>
      <MenuItem onClick={() => handleClick('featured')}>home</MenuItem>
      <MenuItem onClick={() => handleClick('medical-education')}>
        szkolenia on-line
      </MenuItem>
      <MenuItem onClick={() => handleClick('publications')}>
        publikacje medyczne
      </MenuItem>
      <DesktopMenuItem as="a" href="https://itpkonferencje.pl/" target="_blank">
        KONFERENCJE MEDYCZNE
      </DesktopMenuItem>
      <DesktopMenuItem onClick={() => handleClick('about-us')}>
        o nas
      </DesktopMenuItem>
      <DesktopMenuItem as="a" href={itpContactRoute} target="_blank">
        kontakt
      </DesktopMenuItem>
    </Menu>
  );
};
