import React, { FC } from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import styled from 'styled-components';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { TextField } from 'src/common/components/TextField';
import { ChevronDown } from 'src/common/images/ChevronDown';
import { ListboxComponent } from 'src/common/components/ListboxComponent';

const Container = styled.div`
  position: relative;

  &
    .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  & .MuiAutocomplete-endAdornment {
    right: 0.625rem;
  }
`;

export interface SelectControlItem {
  label: string;
  value: string;
}

export type SelectControlProps = {
  items: SelectControlItem[];
  label: string;
  name: string;
  validate?: FieldValidator;
} & Partial<AutocompleteProps<SelectControlItem, false, true, false>>;

export const SelectControl: FC<SelectControlProps> = ({
  items,
  label,
  name,
  validate,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: SelectControlProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { name, value, onBlur, onChange },
      meta,
    }: FieldProps<string>) => (
      <Container>
        <Autocomplete
          {...restProps}
          disableClearable
          disableListWrap
          getOptionLabel={(option) => option.label}
          onChange={(e, v, r, d) => {
            onChange({
              ...e,
              target: {
                ...e.target,
                name,
                value: v.value,
              },
            });
            $onChange?.(e, v, r, d);
          }}
          onBlur={(e) => {
            onBlur({
              ...e,
              target: {
                ...e.target,
                name,
              },
            });
            $onBlur?.(e);
          }}
          options={items}
          popupIcon={<ChevronDown />}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              error={!!(meta.error && meta.touched)}
              label={label}
            />
          )}
          ListboxComponent={ListboxComponent}
        />
      </Container>
    )}
  </Field>
);

SelectControl.displayName = 'SelectControl';
