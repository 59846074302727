import React, { FC, ReactNode } from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import { FieldValidator } from 'formik/dist/types';
import { Checkbox, CheckboxProps } from 'src/common/components/Checkbox';

export interface CheckboxControlProps extends CheckboxProps {
  footer?: boolean;
  name: string;
  label: ReactNode;
  validate?: FieldValidator;
}

export const CheckboxControl: FC<CheckboxControlProps> = ({
  name,
  validate,
  onChange: $onChange,
  label,
  ...restProps
}: CheckboxControlProps) => (
  <Field name={name} validate={validate}>
    {({
      field: { name, value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps<boolean>) => (
      <Checkbox
        name={name}
        size={12}
        value={value}
        label={label}
        onChange={(v) => {
          setFieldTouched(name);
          setFieldValue(name, v);
          $onChange?.(v);
        }}
        {...restProps}
      />
    )}
  </Field>
);

CheckboxControl.displayName = 'CheckboxControl';
