import styled from 'styled-components';
import {
  textUnderlineEffect,
  typographyBaseCss,
} from 'src/common/styles/typography';

export const SimpleLink = styled.a`
  ${typographyBaseCss};
  ${textUnderlineEffect};

  color: currentColor;
  cursor: pointer;
  display: inline-block;
  letter-spacing: unset;
  padding-top: 0;
  text-decoration: underline;
`;
