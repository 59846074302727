import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  videoAcademyFeaturedHeroVideoMobileSelector,
  videoAcademyFeaturedHeroVideoSelector,
} from 'src/state/videoAcademy/videoAcademySelectors';
import { Media } from 'src/common/services/media';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  display: flex;
`;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const HomepageFeaturedBackground = () => {
  const desktopMedia = useSelector(videoAcademyFeaturedHeroVideoSelector);
  const mobileMedia = useSelector(videoAcademyFeaturedHeroVideoMobileSelector);

  if (desktopMedia?.url == null && mobileMedia?.url == null) {
    return <></>;
  }

  return (
    <>
      <Media lessThan="px768">
        <Wrapper>
          <StyledVideo
            src={mobileMedia?.url ?? desktopMedia?.url}
            autoPlay
            loop
            playsInline
            muted
          />
        </Wrapper>
      </Media>
      <Media greaterThanOrEqual="px768">
        <Wrapper>
          <StyledVideo
            src={desktopMedia?.url ?? mobileMedia?.url}
            autoPlay
            loop
            playsInline
            muted
          />
        </Wrapper>
      </Media>
    </>
  );
};
