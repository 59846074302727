import { useCallback, useRef, useState } from 'react';
import { noop } from '@summer/react-kit/functions';

export const useDerivedState = <T>(
  initialValue: T,
  props: { value?: T; onChange?: (a: T) => void }
) => {
  const [value, setValue] = useState<T>(initialValue);

  const controlledValueRef = useRef(props.value ?? initialValue);
  controlledValueRef.current = props.value ?? initialValue;
  const onChangeControlledCallback = useCallback(
    (nextValue: T | ((currentValue: T) => T)) =>
      (props.onChange ?? noop)(
        typeof nextValue === 'function'
          ? (nextValue as (currentValue: T) => T)(controlledValueRef.current)
          : nextValue
      ),
    [props.onChange]
  );

  if (props.value != null || props.onChange != null) {
    return [controlledValueRef.current, onChangeControlledCallback] as const;
  }

  return [value, setValue] as const;
};
