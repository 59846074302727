import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';

const StyledMuiTextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    border-top: 0.25rem solid transparent;
    display: flex;
    margin-bottom: 0.75rem;
  }

  & .MuiFormLabel-root {
    color: ${colors.white};
    font-family: ${typography.fontFamilyHeaders};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    &.MuiInputLabel-shrink {
      left: 0;
      transform: translate(0.25rem, -0.375rem) scale(0.85);
      width: auto;
    }
  }

  & .MuiInput-underline::after,
  & .MuiInput-underline::before,
  & .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: ${colors.white};
  }

  & .MuiInput-underline.Mui-error::after {
    border-bottom-color: ${colors.darkRed};
  }

  & .MuiInputBase-input {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  & .MuiInputBase-root {
    color: ${colors.white};
  }
`;

const FooterTextField = styled(StyledMuiTextField)`
  & .MuiFormLabel-root {
    color: ${colors.white05};
    text-align: left;

    ${media.w.max.px768(css`
      left: 50%;
      text-align: center;
      transform: translate(-50%, 1.5rem) scale(1);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      width: 100%;
    `)}
  }

  & .MuiInput-underline::before,
  & .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: ${colors.white05};
  }

  & .MuiInput-underline.Mui-error::after {
    border-bottom-color: ${colors.lightRed};
  }
`;

export interface TextFieldProps {
  footer?: boolean;
}

export const TextField = ({
  footer,
  ...props
}: TextFieldProps & MuiTextFieldProps) =>
  footer ? (
    <FooterTextField autoComplete="off" {...props} />
  ) : (
    <StyledMuiTextField autoComplete="off" {...props} />
  );

TextField.displayName = 'TextField';
