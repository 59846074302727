import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'src/common/components/Pagination';
import {
  publicationsCurrentPageSelector,
  publicationsTotalSelector,
} from 'src/state/publications/publicationsSelectors';
import { publicationsActions } from 'src/state/publications/publicationsActions';
import styled, { css } from 'styled-components';
import { publicationsPerPage } from 'src/common/models/publications';
import { media } from 'src/config/breakpoints';

const StyledPagination = styled(Pagination)`
  justify-self: center;

  ${media.w.min.px768(css`
    justify-self: end;
  `)}
`;

export const PublicationsPagination: FC = () => {
  const currentPage = useSelector(publicationsCurrentPageSelector);
  const dispatch = useDispatch();
  const total = useSelector(publicationsTotalSelector);

  const handleClick = useCallback((page: number) => {
    dispatch(publicationsActions.setPage(page));
    document?.querySelector('#publications')?.scrollIntoView();
  }, []);

  return (
    <StyledPagination
      handleClick={handleClick}
      currentPage={currentPage}
      itemsPerPage={publicationsPerPage}
      total={total}
      variant="light"
    />
  );
};
