import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { styledIs } from '@summer/react-kit/functions';
import { max, min, range } from 'ramda';
import { StyleProps } from 'src/lib/styleProps';
import { mergeClassName } from '@summer/react-kit';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

const Button = styled(CleanButton)<{ active?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 0.75rem;
  font-weight: 700;

  text-transform: uppercase;
  width: 2.3125rem;
  height: 2.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.3;
  }

  .pagination--dark & {
    border-color: rgba(0, 0, 0, 0.2);
    color: ${colors.grey105};

    &.pagination__item--active {
      background-color: ${colors.itpGold};
      border-color: ${colors.itpGold};
      color: ${colors.itpGrey};
    }

    &:not(:disabled):hover {
      background-color: ${colors.itpGrey};
      border-color: ${colors.itpGrey};
      color: ${colors.white};
    }
  }

  .pagination--light & {
    border-color: rgba(255, 255, 255, 0.2);
    color: ${colors.white};

    &.pagination__item--active {
      background-color: ${colors.itpGold};
      border-color: ${colors.itpGold};
      color: ${colors.itpGrey};
    }

    &:not(:disabled):hover {
      background-color: ${colors.white};
      border-color: ${colors.white};
      color: ${colors.itpGrey};
    }
  }
`;

export interface PaginationProps extends StyleProps {
  handleClick: (i: number) => void;
  currentPage: number;
  itemsPerPage: number;
  total: number;
  variant?: 'light' | 'dark';
}

export const Pagination: FC<PaginationProps> = ({
  handleClick,
  currentPage,
  itemsPerPage,
  total,
  className,
  variant = 'dark',
}) => {
  const pages = useMemo(
    () => max(Math.ceil(total / itemsPerPage), 1),
    [total, itemsPerPage]
  );

  const minPage = min(currentPage - 1, pages - 2);
  const maxPage = max(currentPage + 1, 3);

  const fromPage = max(minPage, 1);
  const toPage = min(maxPage, pages);

  if (pages <= 1) {
    return null;
  }

  return (
    <Container
      className={mergeClassName(className ?? '', `pagination--${variant}`)}
    >
      <Button
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      >
        &lt;
      </Button>

      <Button
        disabled={fromPage === 1}
        onClick={() => handleClick(fromPage - 1)}
      >
        ...
      </Button>

      {range(fromPage, toPage + 1).map((page) => (
        <Button
          className={
            currentPage === page ? `pagination__item--active` : undefined
          }
          key={page}
          onClick={() => handleClick(page)}
        >
          {page}
        </Button>
      ))}

      <Button
        disabled={toPage === pages}
        onClick={() => handleClick(toPage + 1)}
      >
        ...
      </Button>

      <Button
        disabled={currentPage === pages}
        onClick={() => handleClick(currentPage + 1)}
      >
        &gt;
      </Button>
    </Container>
  );
};
