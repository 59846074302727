import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'src/common/components/Button';

const Container = styled.div`
  margin: auto;
  max-width: 14.6875rem;
`;

const StyledButton = styled(Button)`
  justify-content: space-around;
  margin-top: 1.25rem;
  width: 100%;

  svg {
    height: 1rem;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
`;

export interface HomepageRegistrationModalSuccessScreenProps {
  onClose: () => void;
  content: ReactNode;
  buttonLabel?: string;
}

export const HomepageRegistrationModalSuccessScreen: FC<HomepageRegistrationModalSuccessScreenProps> =
  ({ onClose, content, buttonLabel }) => {
    return (
      <Container>
        <Text>{content}</Text>
        <StyledButton onClick={onClose}>
          {buttonLabel != null ? buttonLabel : <>ROZPOCZNIJ</>}
        </StyledButton>
      </Container>
    );
  };
