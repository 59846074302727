import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';

export const HomepageVideoAcademyFilter = styled.div`
  color: ${colors.white};
  font-size: 0.625rem;
  line-height: 0.9375rem;
  margin-bottom: 3.125rem;

  ${media.w.min.px768(css`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
    margin-right: 6.0625rem;
  `)}

  ${media.w.min.px992(css`
    color: ${colors.grey3};
    margin-bottom: 3.75rem;
    margin-right: 0;
    width: 9.6875rem;
  `)}

  ${media.w.min.px1200(css`
    margin-bottom: 4.375rem;
  `)}
`;

export const HomepageVideoAcademyFilterButton = styled(CleanButton)<{
  active: boolean;
}>`
  color: ${colors.white};
  font-size: 0.625rem;
  line-height: 0.9375rem;
  margin: 0 0.6875rem;
  position: relative;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    font-size: 0.875rem;
    line-height: 1.3125rem;
  `)}
  ${({ active }) =>
    media.w.min.px992(css`
      color: ${active ? colors.itpGold : colors.grey1};
      font-size: 0.875rem;
      line-height: 1.3125rem;
    `)}
  &::after {
    background: ${colors.white};
    bottom: 0;
    content: ${({ active }) => (active ? "''" : 'none')};
    display: flex;
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 0;

    ${media.w.min.px992(css`
      background: ${colors.itpGold};
      transition: all;
      animation: underline-animation 1s forwards;
    `)}
  }

  @keyframes underline-animation {
    0% {
      right: 100%;
    }
    100% {
      right: 0;
    }
  }

  span {
    color: ${colors.white05};
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 0.9375rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    ${media.w.min.px992(css`
      color: ${colors.grey105};
      font-size: 0.875rem;
      line-height: 1.3125rem;
      margin-right: 0;
    `)}
  }
`;

export const HomepageVideoAcademyFilterList = styled.div`
  margin: 0 -0.6875rem;
`;

export const HomepageVideoAcademyFilterRow = styled.div`
  margin: 0.75rem 0;
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const HomepageVideoAcademyFilterTitle = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
  position: relative;

  ${media.w.min.px1200(css`
    margin-bottom: 1.5625rem;
  `)}
`;
