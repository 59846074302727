import React, { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { VideoCardSpeaker } from 'src/common/components/VideoCard/VideoCardSpeaker';
import ChevronDownSvg from 'src/assets/images/chevron-down.svg';
import styled, { css, ThemeProvider } from 'styled-components';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { rem } from 'polished';
import { textOverflow } from 'src/common/styles/typography';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';

const Content = styled.div`
  flex: 1 1 auto;

  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const Duration = styled.div`
  color: ${colors.itpGold};
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  display: flex;

  div {
    display: flex;
    margin-left: 0.875rem;

    span {
      margin-left: 0.375rem;
      color: ${colors.grey};
    }
  }
`;

const Tags = styled.div`
  color: ${colors.grey108};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-top: 0.625rem;

  ${media.w.min.px1200(css`
    margin-top: 1.0625rem;
  `)}
  span {
    display: inline-block;
    margin-right: 1.25rem;
    white-space: nowrap;
  }
`;

const Text = styled.div`
  color: ${colors.grey1};
  font-size: 0.875rem;
  line-height: 1.3125rem;

  ${({ theme }) =>
    !theme.open &&
    css`
      height: 2.625rem;
      ${textOverflow(2)}
    `};

  p {
    margin: 0 0 1.3125rem;

    &:last-child {
      margin: 0;
    }
  }
`;

const ChevronDown = styled(ChevronDownSvg)`
  color: ${colors.grey4};
  height: 0.5rem;
`;

const TextButton = styled(CleanButton)`
  display: flex;
  align-items: center;
  padding-top: 0.25rem;

  ${ChevronDown} {
    ${({ theme }) =>
      theme.open &&
      css`
        transform: rotate(180deg);
      `}
  }

  span {
    color: ${colors.grey4};
    font-size: 0.6875rem;
    font-weight: 500;
    margin-left: 0.4375rem;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 0.9375rem;
  margin-top: 0.625rem;
  padding-right: 0.75rem;
  position: relative;
  width: ${({ theme }) => (theme.open ? '100%' : rem(290))};

  ${media.w.min.px992(css`
    padding-right: 0;
  `)}
`;

const Title = styled.div`
  color: ${colors.grey7};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.6875rem;
  margin-top: 0.4375rem;
  text-transform: uppercase;
`;

export interface VideoCardDesktopContentProps {
  item: VideoAcademyItem;
}

export const VideoCardDesktopContent: FC<VideoCardDesktopContentProps> = ({
  item,
}) => {
  const [open, toggleOpen] = useState(false);

  return (
    <ThemeProvider theme={{ open }}>
      <Content>
        <Duration>
          {item.duration?.label}
          {item.languages?.map((language) => (
            <div key={language.id}>
              <img src={language.icon?.url} />
              <span>{language.label}</span>
            </div>
          ))}
        </Duration>

        <Title>{item.title}</Title>

        <TextContainer>
          <Text>
            <ReactMarkdown>{item.description}</ReactMarkdown>
          </Text>

          <TextButton onClick={() => toggleOpen(!open)}>
            <ChevronDown />
            <span>{!open ? 'więcej' : 'mniej'}</span>
          </TextButton>
        </TextContainer>

        {item.speakers?.map((speaker) => (
          <VideoCardSpeaker key={speaker.id} speaker={speaker} />
        ))}

        <Tags>
          {item.tags?.map((tag) => (
            <span key={tag.id}>#{tag.label}</span>
          ))}
        </Tags>
      </Content>
    </ThemeProvider>
  );
};
