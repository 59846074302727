import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { FormItem } from 'src/common/components/FormItem';
import { Button } from 'src/common/components/Button';
import { TextControl } from 'src/common/components/TextControl';
import { media } from 'src/config/breakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { registrationModalNewsletterSelector } from 'src/state/registration/registrationSelectors';
import {
  mapToRegistrationLeadPayload,
  RegistrationLeadFormModel,
} from 'src/common/models/registration';
import { pipe } from 'fp-ts/pipeable';
import { registrationLeadActions } from 'src/state/registration/registrationActions';
import { HomepageNewsletterModal } from 'src/public/components/homepage/HomepageNewsletterModal';
import { useRequestEffect } from 'src/lib/useRequestEffect';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import logoITP from 'src/assets/images/logo-itp.svg';

const Container = styled.div`
  margin: 0 auto;
  max-width: 23.4375rem;
  padding: 2rem 4.375rem 0.9375rem;
  text-align: center;
  width: 85.375rem;

  ${media.w.min.px768(css`
    display: grid;
    grid-template-areas:
      'logo contact'
      'form form'
      'text text';
    grid-template-columns: 50% 50%;
    max-width: 100%;
    padding: 2rem 1.75rem 2rem;
    text-align: left;
  `)}

  ${media.w.min.px1366(css`
    padding-left: 5.125rem;
    padding-right: 3.875rem;
  `)}
`;

const Contact = styled.div`
  color: ${colors.white};
  grid-area: contact;
  line-height: 1.125rem;
  margin-top: 2.125rem;
  text-align: center;

  ${media.w.min.px768(css`
    margin-top: 0.25rem;
    text-align: right;
  `)}
`;

const EmailFormItem = styled.div`
  grid-area: email;
  justify-self: center;

  ${media.w.min.px768(css`
    width: 12rem;
    margin-left: 1.0625rem;
    margin-right: 1.0625rem;
  `)}

  ${media.w.min.px992(css`
    width: 23.625rem;
  `)}
`;

const Info = styled.div`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25rem;
  margin-top: 3.0625rem;

  ${media.w.min.px768(css`
    width: 18rem;
    margin-bottom: 1.625rem;
    margin-top: 0;
  `)}
  ${media.w.min.px992(css`
    width: 26.125rem;
    font-size: 1rem;
    line-height: 1.25rem;
  `)}
  strong {
    font-weight: 700;
  }
`;

const LogoFooter = styled(logoITP)`
  grid-area: logo;
  height: 3.5rem;
  width: 10.875rem;

  ${media.w.min.px768(css`
    height: 4.5rem;
    width: 11rem;
  `)}
`;

const StyledButton = styled(Button)`
  border-width: 0.0625rem;
  font-weight: 500;
  grid-area: button;
  width: 10.75rem;

  ${media.w.min.px768(css`
    margin-bottom: 1.625rem;
  `)}
`;

const StyledForm = styled(Form)`
  grid-area: form;
  margin: 0 auto;
  max-width: 60.625rem;
  padding: 1.25rem 0 4rem;

  ${media.w.min.px768(css`
    align-items: end;
    display: grid;
    grid-template-areas:
      'info email button'
      'privacy privacy privacy';
    padding: 2.5rem 0 1.5rem;
  `)}
`;

const Text = styled.div`
  color: ${colors.itpGold};
  font-size: 0.75rem;
  grid-area: text;
  line-height: 1.125rem;
  text-align: center;

  ${media.w.min.px768(css`
    align-self: center;
    text-align: left;
  `)}
  a {
    color: ${colors.itpGold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Privacy = styled.p`
  grid-area: privacy;
  color: white;
  margin: 0;
`;

const Wrapper = styled.div`
  background: ${colors.itpGrey};
`;

const initialValues: RegistrationLeadFormModel = {
  email: '',
};

const validationSchema = object().shape({
  email: string().required('Wymagane').email('Niepoprawny adres e-mail'),
});

export const footerId = 'footer';

export const HomepageFooter: FC = () => {
  const dispatch = useDispatch();
  const showNewsletterModal = useSelector(registrationModalNewsletterSelector);
  const [isProcessing, setIsProcessing] = useState(false);

  useRequestEffect(registrationLeadActions.request, {
    onSuccess: () => setIsProcessing(false),
    onError: () => setIsProcessing(false),
  });

  return (
    <Wrapper id={footerId}>
      <Container>
        <LogoFooter />

        <Contact>
          Potrzebujesz pomocy technicznej:{' '}
          <SimpleLink
            href="mailto:itpacademy@itpsa.pl"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            itpacademy@itpsa.pl
          </SimpleLink>
          <br />
          Szukasz informacji o naszych produktach:{' '}
          <SimpleLink
            href="mailto:itp@itpsa.pl"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            itp@itpsa.pl
          </SimpleLink>
        </Contact>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            resetForm();
            setIsProcessing(true);
            setSubmitting(false);
            pipe(
              values,
              mapToRegistrationLeadPayload,
              registrationLeadActions.request,
              dispatch
            );
          }}
        >
          <StyledForm autoComplete="off" noValidate>
            <Info>
              <h3>Bądźmy w kontakcie!</h3>
              Zapisz się na nasz newsletter i&nbsp;otrzymuj wiadomości
              o&nbsp;naszych najnowszych ofertach i&nbsp;promocjach!
            </Info>

            <EmailFormItem>
              <FormItem footer name="email">
                <TextControl
                  footer
                  label="Twój adres e-mail"
                  name="email"
                  type="email"
                />
              </FormItem>
            </EmailFormItem>

            <Privacy>
              Zapisując się do Newslettera – będzie Pan/Pani z&nbsp;niego
              korzystać zgodnie z naszym{' '}
              <SimpleLink
                href="/regulamin"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Regulaminem
              </SimpleLink>
              . Wpisanie adresu e-mail stanowi wyrażenie zgody na kierowanie
              przez ITP S.A. informacji marketingowych na podany adres e-mail.
              Pani/Pana dane osobowe będą przetwarzane przez ITP S.A.
              z&nbsp;siedzibą w&nbsp;Warszawie (02–672), ul. Domaniewska 37,
              e-mail:{' '}
              <SimpleLink
                href="mailto:iod@itpsa.pl"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                iod@itpsa.pl
              </SimpleLink>
              . Dane będziemy przetwarzać w&nbsp;celu wysyłki Newslettera. Ma
              Pan/i prawo w&nbsp;każdym czasie wycofać udzieloną zgodę na
              przesyłanie Newslettera, np. pisząc do nas na adres e-mail:{' '}
              <SimpleLink
                href="mailto:marketing@itpsa.pl"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                marketing@itpsa.pl
              </SimpleLink>
              . Przysługują Pani/Panu prawa przyznane przez RODO,
              w&nbsp;szczególności prawo do zapomnienia, sprostowania,
              ograniczenia danych, dostępu do danych, do ich przeniesienia.
              Więcej na temat przetwarzania przez ITP danych osobowych można
              znaleźć w naszej{' '}
              <SimpleLink
                href="/polityka-prywatnosci"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Polityce&nbsp;Prywatności
              </SimpleLink>
              .
            </Privacy>

            <StyledButton disabled={isProcessing} kind="primary" type="submit">
              {!isProcessing && <>Zapisz mnie</>}
              {isProcessing && <LoadingIcon size={32} />}
            </StyledButton>
          </StyledForm>
        </Formik>

        <Text>
          <strong>ITP&nbsp;S.A.</strong>, ul. Domaniewska&nbsp;37,
          02-672&nbsp;Warszawa, Polska; tel.: 722&nbsp;008&nbsp;002; mail:{' '}
          <SimpleLink
            href="mailto:itp@itpsa.pl"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            itp@itpsa.pl
          </SimpleLink>{' '}
          NIP&nbsp;5252360060; KRS&nbsp;0000404795, BDO:&nbsp;000109918, akta
          rejestrowe: Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział
          Gospodarczy KRS; kapitał zakładowy 537&nbsp;061,90&nbsp;zł
          (w&nbsp;całości opłacony) |{' '}
          <SimpleLink
            href="/polityka-prywatnosci"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Polityka&nbsp;Prywatności
          </SimpleLink>{' '}
          |{' '}
          <SimpleLink
            href="/regulamin"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Regulamin
          </SimpleLink>
          <br />
          &copy;{new Date().getFullYear()} ITP S.A. Wszystkie prawa zastrzeżone
        </Text>
      </Container>

      {showNewsletterModal && <HomepageNewsletterModal />}
    </Wrapper>
  );
};
