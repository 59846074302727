import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  videoAcademyCurrentPageSelector,
  videoAcademyTotalSelector,
} from 'src/state/videoAcademy/videoAcademySelectors';
import { itemsPerPage } from 'src/common/models/videoAcademy';
import { videoAcademyActions } from 'src/state/videoAcademy/videoAcademyActions';
import { Pagination } from 'src/common/components/Pagination';

export const HomepageVideoAcademyPagination: FC = () => {
  const currentPage = useSelector(videoAcademyCurrentPageSelector);
  const dispatch = useDispatch();
  const total = useSelector(videoAcademyTotalSelector);

  const handleClick = useCallback((page: number) => {
    dispatch(videoAcademyActions.setPage(page));
    document?.querySelector('#medical-education')?.scrollIntoView();
  }, []);

  return (
    <Pagination
      handleClick={handleClick}
      currentPage={currentPage}
      itemsPerPage={itemsPerPage}
      total={total}
    />
  );
};
