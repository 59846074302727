import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { InnerContainer } from 'src/common/components/Containers';
import { transparentize } from 'polished';

const Background = styled.div`
  background-color: ${transparentize(0.12, '#766240')};
  position: absolute;
  height: 88%;
  width: 70%;
  margin-left: 0;

  top: 0;
  padding: 1.875rem;
  z-index: 1;

  ${media.w.min.px768(css`
    width: 27.25rem;
  `)}
`;

const TextContent = styled.div`
  max-width: 60%;
  padding-top: 7.5rem;
  position: relative;
  z-index: 1;

  ${media.w.min.px768(css`
    max-width: 22.875rem;
  `)}
`;

const Text = styled.div`
  color: ${colors.white};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  ${media.w.min.px992(css`
    font-size: 1.0625rem;
    line-height: 1.5rem;
  `)}
`;

export const HomepageFeaturedBanner = () => {
  return (
    <>
      <Background></Background>
      <InnerContainer>
        <TextContent>
          <Text style={{ textAlign: 'justify' }}>
            ITP Academy to platforma edukacyjna, która dostarcza informacje o
            światowej klasy technologiach, innowacyjnych urządzeniach
            HI&nbsp;TECH oraz dzieli się doświadczeniami najlepszych ekspertów
            na rynku. Zapraszamy do oglądania naszych filmów edukacyjnych
            i&nbsp;poznawania nowych trendów w&nbsp;zabiegach z&nbsp;zakresu
            kosmetologii i&nbsp;medycyny estetycznej.
          </Text>
        </TextContent>
      </InnerContainer>
    </>
  );
};
