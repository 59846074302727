import React, { useEffect } from 'react';
import styled from 'styled-components';
import { HomepageDiscover } from 'src/public/components/homepage/HomepageDiscover';
import { HomepageFooter } from 'src/public/components/homepage/HomepageFooter';
import { registrationModalOpenedSelector } from 'src/state/registration/registrationSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { HomepageRegistrationModal } from 'src/public/components/homepage/HomepageRegistrationModal';
import { Seo } from 'src/public/components/Seo';
import { TrackingScripts } from 'src/common/components/TrackingScripts';
import { HomepageAboutUs } from 'src/public/components/homepage/HomepageAboutUs';
import { HomepageVideoAcademy } from 'src/public/components/homepage/HomepageVideoAcademy';
import { Header } from 'src/public/components/homepage/Header';
import { enableRegistrationBypass } from 'src/state/registration/registrationActions';
import { HomepageFeatured } from 'src/public/components/homepage/HomepageFeatured';
import { HomepagePublications } from 'src/public/components/homepage/HomepagePublications';

const Wrapper = styled.div`
  overflow: hidden;
`;

const IndexPage = () => {
  const registrationModalOpened = useSelector(registrationModalOpenedSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = window?.location.search;
    if (query.includes('qkimptifxbgvdbgyxiqoddidgj')) {
      dispatch(enableRegistrationBypass());
    }
  }, []);

  return (
    <>
      <Header />
      <Wrapper>
        <Seo />
        <HomepageFeatured />
        <HomepageVideoAcademy />
        <HomepagePublications />
        <HomepageAboutUs />
        <HomepageDiscover />
        <HomepageFooter />

        {registrationModalOpened && <HomepageRegistrationModal />}

        <TrackingScripts />
      </Wrapper>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;
