import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const transforms = {
  up: 180,
  down: 0,
  left: 90,
  right: -90,
};

export interface ChevronIconProps extends IconProps {
  direction?: 'up' | 'down' | 'left' | 'right';
}

// eslint-disable-next-line import/no-default-export,react/display-name
export default ({ direction = 'down', ...props }: ChevronIconProps) => (
  <IconBox {...props}>
    <g transform={`rotate(${transforms[direction]} 32 32)`}>
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M1.33894 15.3389c1.78525-1.7852 4.67972-1.7852 6.46498 0L32 39.534l24.1961-24.1951c1.7852-1.7852 4.6797-1.7852 6.465 0 1.7852 1.7852 1.7852 4.6795 0 6.4647L35.2325 49.2311c-1.7853 1.7852-4.6797 1.7852-6.465 0L1.33894 21.8036c-1.78525-1.7852-1.78525-4.6795 0-6.4647z"
      />
    </g>
  </IconBox>
);
