import React, { memo } from 'react';
import { environment } from 'src/config/environment';
import Helmet from 'react-helmet';

export const TrackingScripts = memo(() => {
  const scripts = [];

  if (environment.gtmId != null) {
    scripts.push(
      <script type="text/javascript">
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${environment.gtmId}');
          `}
      </script>
    );
  }

  if (scripts.length > 0) {
    return <Helmet>{scripts}</Helmet>;
  }

  return <></>;
});
