import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { ModalState } from 'src/state/modal/modalReducer';

export const modalStateSelector = (state: RootState) => state.modal;

export const modalFiltersSelector = createSelector(
  modalStateSelector,
  (state: ModalState) => state.filters
);

export const modalHamburgerSelector = createSelector(
  modalStateSelector,
  (state: ModalState) => state.hamburger
);
