import React, { FC, useCallback, useEffect, useState } from 'react';
import { Portal } from 'src/common/components/Portal';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { useDispatch } from 'react-redux';
import { useKeyPress } from 'src/common/hooks/useKeyPress';
import { KEY_ESCAPE } from 'keycode-js';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import HamburgerCloseSvg from 'src/assets/images/hamburger-close.svg';
import { CleanButton } from 'src/common/components/Button';
import { modalActions } from 'src/state/modal/modalActions';
import { ScrollContainer } from 'src/common/components/ScrollContainer';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import OverlayScrollbars from 'overlayscrollbars';
import { HeaderInfoContent } from 'src/public/components/homepage/HeaderInfoContent';
import { media } from 'src/config/breakpoints';

import FiltersIconSvg from 'src/assets/images/filters-icon.svg';
import { itpContactRoute } from 'src/public/publicRoutes';

const CloseButton = styled(CleanButton)`
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  z-index: 100;
  padding: 1rem;

  svg {
    display: block;
    height: 1rem;
  }
`;

const FiltersIcon = styled(FiltersIconSvg)`
  color: ${colors.white};
  height: 1rem;
  margin-left: 0.5rem;

  ${media.w.min.px768(css`
    margin-left: 0.6875rem;
  `)}
`;

const Footer = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;

const Menu = styled.div`
  padding: 10rem 0 5rem;

  ${media.w.min.px768(css`
    padding: 3.75rem 0;
  `)}
`;

const MenuItem = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem 0;

  ${media.w.min.px768(css`
    padding: 1.25rem 0;
  `)}
`;

const MenuLink = styled(CleanButton)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    font-size: 1.5rem;
    line-height: 2.25rem;
  `)}
  &::after {
    background: ${colors.white03};
    content: '';
    display: block;
    height: 0.125rem;
    width: 3.75rem;

    ${media.w.min.px768(css`
      margin-top: -0.125rem;
    `)}
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5625rem;
  width: 100%;

  ${media.w.min.px768(css`
    padding: 0 3.5rem;
  `)}
`;

const SortByButton = styled(CleanButton)`
  align-items: center;
  color: ${colors.white};
  display: flex;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-left: 2.5rem;

  ${media.w.min.px768(css`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 3.5rem;
  `)}
`;

const StyledScrollContainer = styled(ScrollContainer)`
  flex: 1 1 auto;
`;

const Wrapper = styled.div`
  background: ${colors.itpGold};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const scrollbarOptions: OverlayScrollbars.Options = {
  overflowBehavior: { x: 'hidden' },
};

export const HeaderHamburgerModal: FC = () => {
  const dispatch = useDispatch();
  const [ref, setRef] = useState<OverlayScrollbarsComponent | null>(null);

  const handleClose = useCallback(
    () => dispatch(modalActions.toggleHamburger(false)),
    []
  );

  const scrollTo = useCallback((id: string) => {
    document?.querySelector(`#${id}`)?.scrollIntoView({
      behavior: 'smooth',
    });

    handleClose();
  }, []);

  useEffect(() => {
    const viewport = ref?.osInstance()?.getElements().viewport;

    if (viewport) {
      disableBodyScroll(viewport);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [ref]);

  useKeyPress(KEY_ESCAPE, handleClose);

  return (
    <Portal>
      <Wrapper>
        <CloseButton onClick={handleClose}>
          <HamburgerCloseSvg />
        </CloseButton>

        <StyledScrollContainer options={scrollbarOptions} ref={setRef}>
          <ModalContent>
            <Menu>
              <MenuItem>
                <MenuLink onClick={() => scrollTo('featured')}>home</MenuLink>
              </MenuItem>

              <MenuItem>
                <MenuLink onClick={() => scrollTo('medical-education')}>
                  szkolenia on-line
                </MenuLink>

                <SortByButton
                  onClick={() => dispatch(modalActions.toggleFilters(true))}
                >
                  Sortuj
                  <FiltersIcon />
                </SortByButton>
              </MenuItem>

              <MenuItem>
                <MenuLink onClick={() => scrollTo('publications')}>
                  publikacje medyczne
                </MenuLink>
              </MenuItem>

              <MenuItem>
                <MenuLink
                  as="a"
                  href="https://itpkonferencje.pl/"
                  target="_blank"
                >
                  KONFERENCJE MEDYCZNE
                </MenuLink>
              </MenuItem>

              <MenuItem>
                <MenuLink onClick={() => scrollTo('about-us')}>o nas</MenuLink>
              </MenuItem>

              <MenuItem>
                <MenuLink as="a" href={itpContactRoute} target="_blank">
                  kontakt
                </MenuLink>
              </MenuItem>
            </Menu>
          </ModalContent>
        </StyledScrollContainer>

        <Footer>
          <HeaderInfoContent />
        </Footer>
      </Wrapper>
    </Portal>
  );
};
