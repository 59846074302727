import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import React, { FC } from 'react';
import { StyleProps } from 'src/lib/styleProps';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';
import { VideoTitledThumbnailFooter } from 'src/common/components/VideoTitledThumbnail/VideoTitledThumbnailFooter';

export interface VideoTitledThumbnailProps {
  item: VideoAcademyItem;
}

const Background = styled.div<{ image?: string }>`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:hover {
    &::before {
      transform: scale(1.1);
    }

    .footer-wrapper {
      max-height: 50%;
    }

    .footer-icon {
      min-height: 2.1875rem;
      min-width: 2.1875rem;
    }

    .play-icon-bg {
      fill: ${colors.itpGold};
    }

    .play-icon-circle {
      fill: ${colors.itpGold};
    }

    .play-icon-arrow {
      fill: ${colors.white};
    }
  }

  &::before {
    background-size: cover;
    content: '';
    mix-blend-mode: multiply;
    width: 100%;
    transition: all 0.5s;

    ${({ image }) =>
      image &&
      css`
        background-image: url('${image}');
      `}
  }
`;

export const VideoTitledThumbnail: FC<VideoTitledThumbnailProps & StyleProps> =
  ({ item, className, style }) => {
    return (
      <Background
        className={className}
        image={item.thumbnail?.url}
        style={style}
      >
        <VideoTitledThumbnailFooter title={item.title} />
      </Background>
    );
  };
