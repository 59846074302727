import React, { FC, useCallback, useEffect, useState } from 'react';
import itpAcademyLogo from 'src/assets/images/itpacademy-logo.svg';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { HeaderMenu } from 'src/public/components/homepage/Header/HeaderMenu';
import { HeaderMobile } from 'src/public/components/homepage/Header/HeaderMobile';
import { colors } from 'src/config/colors';
import { HeaderInfo } from 'src/public/components/homepage/HeaderInfo';
import { MaxWidthContainer } from 'src/common/components/Containers';
import { CSSTransition } from 'react-transition-group';
import { registrationActions } from 'src/state/registration/registrationActions';
import { RegistrationSteps } from 'src/state/registration/registrationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { registrationCurrentStepSelector } from 'src/state/registration/registrationSelectors';
import { featuredWrapperId } from 'src/public/components/homepage/HomepageFeatured';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;

  ${media.w.min.px992(css`
    padding: 0 2rem 0 0;
  `)}

  ${media.w.min.px1200(css`
    height: 4.375rem;
  `)}
`;

const Logo = styled.div`
  flex: 0 0 auto;
  padding-left: 1.875rem;
  display: flex;

  ${media.w.min.px992(css`
    padding-left: 2rem;
  `)}

  ${media.w.min.px1200(css`
    padding-left: 1.9375rem;
  `)}
`;

const ITPLogo = styled(itpAcademyLogo)`
  height: auto;
  width: 6rem;

  ${media.w.min.px768(css`
    width: 7.75rem;
  `)}
`;

const Wrapper = styled.div<{ fixed: boolean }>`
  transition: background 0.5s ease;

  background: #766240;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
  padding: 0.75rem 0;

  ${media.w.min.px992(css`
    padding: 1rem 0 0.75rem 0;
  `)}
`;

const OuterWrapper = styled(MaxWidthContainer)`
  position: relative;
  ${media.w.max.px1200(css`
    max-width: 100%;
  `)}
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoutToast = styled.div`
  background-color: ${colors.itpGold};
  padding: 0 1rem;
  height: 3rem;
  position: absolute;
  top: -1rem;
  right: 430px;
  display: flex;
  align-items: flex-end;

  color: ${colors.white08};
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.75rem;
  text-transform: uppercase;

  ${media.w.max.px992(css`
    display: none;
  `)}
  span {
    padding-bottom: 0.6125rem;
  }
`;

export const Header: FC = () => {
  const dispatch = useDispatch();
  const [headerFixed, setHeaderFixed] = useState(false);
  const currentStep = useSelector(registrationCurrentStepSelector);

  const changeHeaderStyle = () => {
    const scrollBreakpoint =
      (document.getElementById(featuredWrapperId)?.scrollHeight ?? 900) - 200;

    if (window.scrollY >= scrollBreakpoint) {
      setHeaderFixed(true);
    } else {
      setHeaderFixed(false);
    }
  };

  const goToInitStep = useCallback(() => {
    dispatch(registrationActions.goToInitStep());
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', changeHeaderStyle);
  }, []);

  return (
    <Wrapper fixed={headerFixed}>
      <OuterWrapper>
        <CSSTransition
          in={currentStep === RegistrationSteps.Logout}
          timeout={800}
          classNames="show"
          onEnter={() => setTimeout(goToInitStep, 3000)}
          unmountOnExit
        >
          <LogoutToast>
            <span>Zostałeś wylogowany!</span>
          </LogoutToast>
        </CSSTransition>
        <InnerWrapper>
          <Logo>
            <ITPLogo />
          </Logo>
          <Container>
            <HeaderInfo />
            <HeaderMenu />
            <HeaderMobile />
          </Container>
        </InnerWrapper>
      </OuterWrapper>
    </Wrapper>
  );
};
