import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  videoAcademyCategoriesSelector,
  videoAcademyDurationSelector,
  videoAcademyLanguageSelector,
  videoAcademySubjectSelector,
} from 'src/state/videoAcademy/videoAcademySelectors';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { videoAcademyActions } from 'src/state/videoAcademy/videoAcademyActions';
import { HomepageVideoAcademyCategoryFilter } from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyCategoryFilter';
import { media } from 'src/config/breakpoints';

const Panel = styled.div`
  flex: 0 0 14.75rem;
  padding: 0 0 0 2.5rem;
  position: relative;
  z-index: 20;

  ${media.w.max.px992(css`
    display: none;
  `)}

  ${media.w.min.px1200(css`
    flex-basis: 23.75rem;
    padding-left: 4.625rem;
  `)}
`;

const Title = styled.div`
  color: ${colors.grey2};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  margin-bottom: 3.4375rem;
  max-width: 15.5rem;
  text-transform: uppercase;

  &::after {
    background-color: ${colors.grey2};
    content: '';
    display: block;
    height: 0.25rem;
    margin-top: 0.3125rem;
    width: 3.75rem;
  }
`;

export const HomepageVideoAcademyPanel: FC = () => {
  const activeDuration = useSelector(videoAcademyDurationSelector);
  const activeLanguage = useSelector(videoAcademyLanguageSelector);
  const activeSubject = useSelector(videoAcademySubjectSelector);
  const categories = useSelector(videoAcademyCategoriesSelector);

  return (
    <Panel>
      <Title>Szkolenia on-line / Webinary</Title>

      <HomepageVideoAcademyCategoryFilter
        active={activeDuration}
        action={videoAcademyActions.setDuration}
        items={categories?.durations}
        label="Czas"
        total={categories?.total}
      />

      <HomepageVideoAcademyCategoryFilter
        active={activeSubject}
        action={videoAcademyActions.setSubject}
        items={categories?.subjects}
        label="Tematyka"
        total={categories?.total}
      />

      <HomepageVideoAcademyCategoryFilter
        active={activeLanguage}
        action={videoAcademyActions.setLanguage}
        items={categories?.languages}
        label="Język"
        total={categories?.total}
      />
    </Panel>
  );
};
