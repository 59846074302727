import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { ErrorMessage, useField } from 'formik';
import { isFunctionComponentElement } from 'src/lib/type.utils';

type ChildType =
  | 'SelectControl'
  | 'TextControl'
  | 'TextField'
  | 'CheckboxControl'
  | string;

const childTypeSpacing: Record<ChildType, ReturnType<typeof css>> = {
  ['SelectControl']: css`
    margin-top: -0.75rem;
    padding: 0.1875rem 0 0;
  `,
  ['TextField']: css`
    margin-top: -0.75rem;
    padding: 0.1875rem 0 0;
  `,
  ['TextControl']: css`
    margin-top: -0.75rem;
    padding: 0.1875rem 0 0;
  `,
  ['CheckboxControl']: css`
    margin-top: -0.25rem;
    padding: 0.375rem 1.25rem 0 1.625rem;
  `,
};

const Description = styled.div<{
  childType?: ChildType;
  footer?: boolean;
}>`
  color: green;
  font-size: 0.625rem;
  line-height: 0.875rem;
  margin-bottom: 0.375rem;
  min-height: 1.25rem;
  opacity: 0.5;
  padding: 0.1875rem 0.4375rem 0;
  text-align: left;

  ${({ childType }) => (childType ? childTypeSpacing[childType] : '')};
`;

const ErrorContainer = styled(Description)`
  color: ${({ footer }) => (footer ? colors.lightRed : colors.darkRed)};
  opacity: 1;
`;

const getDisplayName = (a: ReactNode) => {
  if (!isFunctionComponentElement(a)) {
    return '';
  }
  return a.type.displayName;
};

export interface FormItemProps {
  description?: string;
  footer?: boolean;
  name: string;
}

export const FormItem: FC<FormItemProps> = ({
  description,
  footer,
  name,
  children,
}) => {
  const [, meta] = useField(name);

  const hasError = meta.error && meta.touched;

  return (
    <>
      {children}

      {!hasError && (
        <Description childType={getDisplayName(children)} footer={footer}>
          {description}
        </Description>
      )}

      {hasError && (
        <ErrorContainer childType={getDisplayName(children)} footer={footer}>
          <ErrorMessage name={name} component="div" />
        </ErrorContainer>
      )}
    </>
  );
};
