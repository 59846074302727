import { css } from 'styled-components';
import { typography } from 'src/config/typography';

export type TextDecoration = 'default' | 'underline';
export type TextVariant = 'default' | 'headers';
export type TextSize = 'default';

export interface TypographyBase {
  decoration?: TextDecoration;
  variant?: TextVariant;
}

export const typographyBaseCss = css<TypographyBase>`
  font-family: ${({ variant }) =>
    'headers' === variant
      ? typography.fontFamilyHeaders
      : typography.fontFamilyDefault};
`;

export const textOverflow = (lines: number) => css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
`;

export const textOverflowLines = (lines: number) => css`
  -webkit-line-clamp: ${lines};
`;

export const textUnderlineEffect = css<TypographyBase>`
  padding-top: 0.1875rem;
  position: relative;
  text-decoration: ${({ decoration }) =>
    'underline' === decoration ? 'underline' : 'none'};

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:active {
    color: currentColor;
  }
`;

export const typographySizeConfig: Record<TextSize, ReturnType<typeof css>> = {
  ['default']: css`
    font-size: 0.75rem;
  `,
};
