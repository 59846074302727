import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import {
  RegistrationState,
  RegistrationSteps,
} from 'src/state/registration/registrationReducer';

export const registrationStateSelector = (state: RootState) =>
  state.registration;

export const registrationCurrentStepSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) => state.currentStep
);

export const registrationEmailSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) => state.email
);

export const registrationModalOpenedSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) => state.opened
);

export const registrationModalNewsletterSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) => state.newsletterSuccess
);

export const isRegisteredSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) =>
    [RegistrationSteps.Exists, RegistrationSteps.Success].includes(
      state.currentStep
    )
);

export const shouldSeeRestrictedContentSelector = createSelector(
  registrationStateSelector,
  isRegisteredSelector,
  (state: RegistrationState, isRegistered: boolean) =>
    isRegistered || state.bypass
);

export const registrationModalVideoItemSelector = createSelector(
  registrationStateSelector,
  (state: RegistrationState) => state.videoItem
);
