import React, { FC } from 'react';
import { Publication } from 'src/common/models/publications';
import { useDispatch, useSelector } from 'react-redux';
import { shouldSeeRestrictedContentSelector } from 'src/state/registration/registrationSelectors';
import styled from 'styled-components';
import DownloadIconSVG from 'src/assets/images/download-icon.svg';
import { Button } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { prependApiUrl } from 'src/config/environment';
import { registrationActions } from 'src/state/registration/registrationActions';

const DownloadIcon = styled(DownloadIconSVG)`
  margin-right: 0.6875rem;
  color: ${colors.itpGold};
`;

const DownloadButton = styled(Button)`
  &:hover {
    ${DownloadIcon} {
      color: ${colors.white};
    }
  }
`;

export interface PublicationDownloadButtonProps {
  publication: Publication;
}

export const PublicationDownloadButton: FC<PublicationDownloadButtonProps> = ({
  publication,
}) => {
  const dispatch = useDispatch();
  const showRestrictedContent = useSelector(shouldSeeRestrictedContentSelector);

  return (
    <>
      {showRestrictedContent ? (
        <DownloadButton
          as="a"
          href={prependApiUrl(publication.filePdf.url)}
          target="_blank"
          kind="gold"
          size="sm"
          download={`${publication.filePdf.name}`}
        >
          <DownloadIcon />
          pobierz
        </DownloadButton>
      ) : (
        <DownloadButton
          onClick={() => dispatch(registrationActions.toggleModal(true))}
          kind="gold"
          size="sm"
        >
          <DownloadIcon />
          pobierz
        </DownloadButton>
      )}
    </>
  );
};
