import React, { FC, useCallback } from 'react';
import EventMoreSvg from 'src/assets/images/event-more.svg';
import styled, { css, keyframes } from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { InnerContainer } from 'src/common/components/Containers';

const flow0 = keyframes`
  0%, 100% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.5;
  }

  75% {
    opacity: 1;
  }
`;

const flow1 = keyframes`
  0%, 100% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.9;
  }
`;

const flow2 = keyframes`
  0%, 100% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.2;
  }
`;

const More = styled(CleanButton)`
  align-items: center;
  transform: translateX(-50%);

  .arrow-0 {
    animation: ${flow0} 1.75s 0.5s linear infinite;
  }

  .arrow-1 {
    animation: ${flow1} 1.75s 0.5s linear infinite;
  }

  .arrow-2 {
    animation: ${flow2} 1.75s 0.5s linear infinite;
  }

  ${media.w.min.px768(css`
    display: flex;
    transform: none;
    position: relative;
    z-index: 1;
  `)}
  span {
    color: ${colors.itpGrey};
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.75rem;
    margin-left: 0.75rem;

    ${media.w.min.px1200(css`
      font-size: 0.75rem;
      line-height: 0.9375rem;
    `)}
  }

  svg {
    height: 1.3125rem;
  }
`;

const EventMoreIcon = styled(EventMoreSvg)`
  color: ${colors.white};

  ${media.w.min.px768(css`
    color: ${colors.itpGrey};
  `)}
`;

const MobileOverlay = styled.div`
  width: 100%;
  background: linear-gradient(${colors.black08}, ${colors.black} 100%);
  height: 15%;
  bottom: 0;
  z-index: 20;
  position: absolute;
  display: flex;
  justify-content: center;
`;

const MoreDesktop = styled.span`
  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const MoreMobile = styled.span`
  ${media.w.min.px768(css`
    display: none;
  `)}
`;

export const HomepageFeaturedMore: FC = () => {
  const scrollToVideoAcademy = useCallback(() => {
    document?.querySelector(`#medical-education`)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <MoreMobile>
        <MobileOverlay onClick={scrollToVideoAcademy}>
          <More>
            <EventMoreIcon />
          </More>
        </MobileOverlay>
      </MoreMobile>
      <MoreDesktop>
        <InnerContainer>
          <More onClick={scrollToVideoAcademy}>
            <EventMoreIcon />
            <MoreDesktop>Przewiń, aby zobaczyć więcej</MoreDesktop>
          </More>
        </InnerContainer>
      </MoreDesktop>
    </>
  );
};
