import React, { FC, useCallback } from 'react';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { useDispatch, useSelector } from 'react-redux';
import { isRegisteredSelector } from 'src/state/registration/registrationSelectors';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { CleanButton } from 'src/common/components/Button';
import { registrationActions } from 'src/state/registration/registrationActions';
import { media } from 'src/config/breakpoints';
import { modalActions } from 'src/state/modal/modalActions';
import 'src/assets/styles.scss';

const Container = styled.div`
  align-items: center;
  color: ${colors.white08};
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
  height: 3rem;
  line-height: 0.75rem;
  padding: 0 0.5rem 0;
  text-transform: uppercase;

  ${media.w.min.px768(css`
    font-size: 0.875rem;
    height: 5.625rem;
    line-height: 1.0625rem;
    margin-bottom: 0.5rem;
  `)}

  ${media.w.min.px992(css`
    font-size: 0.75rem;
    height: auto;
    justify-content: flex-end;
    line-height: 0.75rem;
    padding: 0;
  `)}

  ${media.w.min.px1200(css`
    margin-bottom: 0;
  `)}

  span {
    margin-right: 0.25rem;
  }
`;

const HeaderInfoSeparator = styled.div`
  font-weight: 300;
  margin: 0 0.6875rem;

  ${media.w.max.px992(css`
    display: none;
  `)}
`;

const AuthButton = styled(CleanButton)`
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
`;

const Divider = styled.span`
  font-weight: 700;
  margin: 0 0.5rem !important;
  display: inline-block;
`;

const LoggedInInfo = styled.div`
  ${media.w.max.px992(css`
    display: none;
  `)}
  b {
    font-weight: 700;
  }

  span {
    font-weight: 700;
    margin-left: 0.25rem;
    padding-right: 0.5rem;
  }
`;

const StyledSimpleLink = styled(SimpleLink)`
  margin-right: auto;

  ${media.w.min.px992(css`
    margin-right: 0;
  `)}
`;

export const HeaderInfoContent: FC = () => {
  const dispatch = useDispatch();
  const isRegistered = useSelector(isRegisteredSelector);

  const openProcessModal = useCallback(() => {
    dispatch(modalActions.toggleHamburger(false));
    dispatch(registrationActions.goToInitStep());
    dispatch(registrationActions.toggleModal(true));
  }, []);

  const openRegistrationModal = useCallback(() => {
    dispatch(modalActions.toggleHamburger(false));
    dispatch(registrationActions.goToRegistrationStep());
    dispatch(registrationActions.toggleModal(true));
  }, []);

  const logoutUser = useCallback(() => {
    dispatch(registrationActions.logout());
  }, []);

  return (
    <Container>
      <span>Odwiedź</span>
      <StyledSimpleLink
        href="https://itpsa.pl/"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        www.itpsa.pl
      </StyledSimpleLink>
      <HeaderInfoSeparator>|</HeaderInfoSeparator>
      {isRegistered && (
        <>
          <LoggedInInfo>
            <b>Jesteś zalogowany</b> <span>/</span>
          </LoggedInInfo>
          <AuthButton onClick={logoutUser}>
            <b>Wyloguj</b>
          </AuthButton>
        </>
      )}
      {!isRegistered && (
        <>
          <AuthButton onClick={openProcessModal}>Zaloguj</AuthButton>
          <Divider>/</Divider>
          <AuthButton onClick={openRegistrationModal}>
            Zarejestruj się
          </AuthButton>
        </>
      )}
    </Container>
  );
};
