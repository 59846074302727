import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import React, { FC } from 'react';
import Play3xSvg from 'src/assets/images/play-icon-sharp.svg';
import { StyleProps } from 'src/lib/styleProps';

const Background = styled.div<{ image?: string }>`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;

  &:hover {
    .play3x-circle {
      fill: ${colors.itpGold};
      stroke: ${colors.itpGold};
    }

    /* .play3x-circle-path {
      fill: ${colors.itpGold};
    }

    .play3x-path {
      fill: ${colors.white};
    } */

    &::before {
      transform: scale(1.1);
    }
  }

  &::after {
    background: ${colors.grey3};
    bottom: 0;
    content: '';
    left: 0;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }

  &::before {
    background: ${colors.grey3} center no-repeat;
    background-size: cover;
    content: '';
    mix-blend-mode: multiply;
    width: 100%;
    z-index: 20;
    transition: all 0.5s;

    ${({ image }) =>
      image &&
      css`
        background-image: url('${image}');
      `}
  }
`;

const ItemPlay = styled.div`
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
`;

const Play3x = styled(Play3xSvg)`
  display: block;
  height: 8.125rem;
  color: white;
`;

export interface VideoCardBackgroundProps {
  image?: string;
}

export const VideoCardBackground: FC<VideoCardBackgroundProps & StyleProps> = ({
  className,
  image,
  style,
}) => (
  <Background className={className} image={image} style={style}>
    <ItemPlay>
      <Play3x />
    </ItemPlay>
  </Background>
);
