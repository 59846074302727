import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { VideoCardBackground } from 'src/common/components/VideoCardBackground';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';
import { useDispatch, useSelector } from 'react-redux';
import { shouldSeeRestrictedContentSelector } from 'src/state/registration/registrationSelectors';
import { registrationActions } from 'src/state/registration/registrationActions';
import { media } from 'src/config/breakpoints';
import { VideoCardDesktopContent } from 'src/common/components/VideoCard/VideoCardDesktopContent';
import { VideoCardMobileContent } from 'src/common/components/VideoCard/VideoCardMobileContent';
import { wistiaPlayerActions } from 'src/state/wistiaPlayer/wistiaPlayerActions';
import { navigate } from 'gatsby';

const Container = styled.div`
  margin-bottom: 3.4375rem;

  ${media.w.min.px768(css`
    display: flex;
    margin-bottom: 3.125rem;
  `)}

  ${media.w.min.px1200(css`
    margin-bottom: 5.625rem;
  `)}
`;

const Thumbnail = styled.a`
  cursor: pointer;
  display: flex;
  flex: 0 0 20.9375rem;
  height: 11.6875rem;

  ${media.w.min.px768(css`
    flex-basis: 17.8125rem;
    height: 9.9375rem;
    margin-right: 1.5625rem;
  `)}
  ${media.w.min.px1200(css`
    flex-basis: 25.4375rem;
    height: 14.1875rem;
  `)}
  svg {
    ${media.w.max.px1200(css`
      height: 3.9375rem;
    `)}

    ${media.w.max.px768(css`
      height: 4.6875rem;
    `)}
  }
`;

export interface VideoCardProps {
  item: VideoAcademyItem;
}

export const VideoCard: FC<VideoCardProps> = ({ item }) => {
  const dispatch = useDispatch();
  const showRestrictedContent = useSelector(shouldSeeRestrictedContentSelector);

  const handleClick = useCallback(() => {
    if (showRestrictedContent) {
      dispatch(registrationActions.setVideoItem(item));
      dispatch(wistiaPlayerActions.setMainPlayerPlaying(false));
      navigate(`/videos/${item.id}`);
    } else {
      dispatch(registrationActions.toggleModal(true));
    }
  }, [showRestrictedContent]);

  return (
    <Container>
      <Thumbnail onClick={handleClick}>
        <VideoCardBackground image={item.thumbnail?.url} />
      </Thumbnail>

      <VideoCardDesktopContent item={item} />
      <VideoCardMobileContent item={item} />
    </Container>
  );
};
