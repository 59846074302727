import React, { forwardRef, HTMLAttributes } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import styled from 'styled-components';

const itemSize = 48;
const listboxPadding = 8;

const getHeight = (itemCount: number) =>
  itemCount > 8 ? 8 * itemSize : itemCount * itemSize;

const renderRow = ({ data, index, style }: ListChildComponentProps) =>
  React.cloneElement(data[index], {
    style: {
      ...style,
      top: +(style?.top || 0) + listboxPadding,
    },
  });

const OuterElementContext = React.createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const StyledList = styled.ul`
  margin: 0;
`;

export const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, ...other }, ref) => {
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={getHeight(itemCount) + 2 * listboxPadding}
          width="100%"
          itemSize={48}
          itemCount={itemCount}
          outerElementType={OuterElementType}
          innerElementType={StyledList}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
