import React, { FC } from 'react';
import { Category } from 'src/common/models/videoAcademy';
import { useDispatch, useSelector } from 'react-redux';
import {
  HomepageVideoAcademyFilter,
  HomepageVideoAcademyFilterButton,
  HomepageVideoAcademyFilterList,
  HomepageVideoAcademyFilterRow,
  HomepageVideoAcademyFilterTitle,
} from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyFilter';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { videoAcademyCategoriesSelector } from 'src/state/videoAcademy/videoAcademySelectors';

const Loader = styled.div`
  color: ${colors.itpGold};
  text-align: center;
`;

const LanguageIcon = styled.img`
  margin-left: 0.75rem;
  width: 12px;
`;

export interface HomepageVideoAcademyCategoryFilterProps {
  active: string | null;
  action: ActionCreatorWithPayload<string | null>;
  items?: Category[];
  label: string;
  total?: number;
}

export const HomepageVideoAcademyCategoryFilter: FC<HomepageVideoAcademyCategoryFilterProps> =
  ({ active, action, items, label, total }) => {
    const dispatch = useDispatch();
    const categories = useSelector(videoAcademyCategoriesSelector);

    return (
      <HomepageVideoAcademyFilter>
        <HomepageVideoAcademyFilterTitle>
          {label}
        </HomepageVideoAcademyFilterTitle>

        {!categories && (
          <Loader>
            <LoadingIcon size={36} />
          </Loader>
        )}

        {categories && (
          <HomepageVideoAcademyFilterList>
            <HomepageVideoAcademyFilterRow>
              <HomepageVideoAcademyFilterButton
                active={!active}
                onClick={() => dispatch(action(null))}
              >
                Wszystkie
                {total && <span>{total}</span>}
              </HomepageVideoAcademyFilterButton>
            </HomepageVideoAcademyFilterRow>

            {items?.map((item) => (
              <HomepageVideoAcademyFilterRow key={item.id}>
                {label === 'Language' && <LanguageIcon src={item.icon?.url} />}
                <HomepageVideoAcademyFilterButton
                  active={active === item.id}
                  onClick={() => dispatch(action(item.id))}
                >
                  {item.label}
                  <span>{item.count}</span>
                </HomepageVideoAcademyFilterButton>
              </HomepageVideoAcademyFilterRow>
            ))}
          </HomepageVideoAcademyFilterList>
        )}
      </HomepageVideoAcademyFilter>
    );
  };
