import React, { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { HomepageVideoAcademyPanel } from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyPanel';
import { VideoCard } from 'src/common/components/VideoCard';
import {
  getCategoriesActions,
  getCountActions,
  getItemsActions,
} from 'src/state/videoAcademy/videoAcademyActions';
import { useDispatch, useSelector } from 'react-redux';
import { videoAcademyItemsSelector } from 'src/state/videoAcademy/videoAcademySelectors';
import { HomepageVideoAcademyPagination } from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyPagination';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';
import { ScrollOffset } from 'src/common/components/ScrollOffset';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { modalFiltersSelector } from 'src/state/modal/modalSelectors';
import { HomepageVideoAcademyFiltersModal } from 'src/public/components/homepage/HomepageVideoAcademy/HomepageVideoAcademyFiltersModal';
import { modalActions } from 'src/state/modal/modalActions';
import { CleanButton } from 'src/common/components/Button';
import FiltersIconSvg from 'src/assets/images/filters-icon.svg';

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 5.9375rem 0 3.25rem;
  position: relative;
  width: 85.375rem;

  ${media.w.min.px768(css`
    padding: 6.875rem 0 3.125rem;
  `)}

  ${media.w.min.px992(css`
    display: flex;
    padding: 10.75rem 0 5.875rem;
  `)}

  ${media.w.min.px1200(css`
    padding-top: 10.75rem;
  `)}
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  z-index: 20;

  ${media.w.min.px992(css`
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  `)}

  ${media.w.min.px992(css`
    padding-left: 0;
    padding-right: 3.125rem;
  `)}

  ${media.w.min.px1200(css`
    padding-right: 7.375rem;
  `)}
`;

const FiltersIcon = styled(FiltersIconSvg)`
  color: ${colors.itpGold};
  height: 1rem;

  ${media.w.min.px768(css`
    margin-left: 0.75rem;
  `)}
`;

const Loader = styled.div`
  color: ${colors.itpGold};
  text-align: center;
`;

const SortByButton = styled(CleanButton)`
  align-items: center;
  color: ${colors.itpGold};
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1.875rem;

  span {
    text-decoration: underline;

    ${media.w.max.px768(css`
      display: none;
    `)}
  }

  ${media.w.min.px768(css`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 3.5rem;
  `)}
`;

const Title = styled.div`
  color: ${colors.grey2};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: uppercase;

  &::after {
    background-color: ${colors.grey2};
    content: '';
    display: block;
    height: 0.25rem;
    margin-top: -0.125rem;
    width: 8.125rem;

    ${media.w.min.px768(css`
      width: 3.75rem;
    `)}

    ${media.w.min.px992(css`
      margin-top: 0.3125rem;
    `)}
  }
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 2rem 1.5rem;
  position: relative;
  z-index: 20;

  ${media.w.min.px768(css`
    padding: 0 0 4rem 2.125rem;
  `)}

  ${media.w.min.px992(css`
    display: none;
  `)}
`;

const Wrapper = styled.div`
  background: ${colors.white};
  position: relative;
`;

export const HomepageVideoAcademy: FC = () => {
  const dispatch = useDispatch();

  const items = useSelector(videoAcademyItemsSelector);
  const showFiltersModal = useSelector(modalFiltersSelector);

  useEffect(() => {
    dispatch(getCategoriesActions.request());
    dispatch(getCountActions.request());
    dispatch(getItemsActions.request());
  }, [dispatch]);

  return (
    <Wrapper>
      <ScrollOffset id="medical-education" />

      <Container>
        <TitleContainer>
          <Title>Szkolenia on-line / webinary</Title>

          <SortByButton
            onClick={() => dispatch(modalActions.toggleFilters(true))}
          >
            <span>Sortuj</span>
            <FiltersIcon />
          </SortByButton>
        </TitleContainer>

        <HomepageVideoAcademyPanel />

        <Content>
          {!items && (
            <Loader>
              <LoadingIcon size={64} />
            </Loader>
          )}

          {items?.map((item) => (
            <VideoCard item={item} key={item.id} />
          ))}

          <HomepageVideoAcademyPagination />
        </Content>
      </Container>

      {showFiltersModal && <HomepageVideoAcademyFiltersModal />}
    </Wrapper>
  );
};
